<template>
    <v-card style="height: 100%; overflow-y: auto; width: 100%;">
        <!-- Card header -->
        <v-card-title class="d-flex justify-space-between align-center mt-6 ml-7">
            <span class="text-h5">Edit {{ getPatientFullName(patient) }}'s details</span>
            <v-btn icon elevation="0" @click="closeButtonTap">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <!-- Card body -->
        <v-card-text>
            <v-form class="d-flex flex-column border-0" style="box-shadow: none; max-width: 100% !important;"
                @submit.prevent>

                <!-- Patient name or patient first name & last name -->
                <template v-if="localPatient.patientFirstName">
                    <v-text-field class="mt-8" v-model="localPatient.patientFirstName" color="accent" label="First Name"
                        variant="underlined" density="compact">
                    </v-text-field>
                    <v-text-field class="left-aligned-hint" v-model="localPatient.patientLastName" color="accent"
                        label="Last Name" variant="underlined" density="compact" hint="Optional"
                        persistent-hint>
                    </v-text-field>
                </template>
                <template v-else>
                    <v-text-field class="mt-8" v-model="localPatient.patientName" color="accent" label="Name"
                        variant="underlined" density="compact">
                    </v-text-field>
                </template>

                <!-- Custom pickers for the gender & pronouns -->
                <div class="d-flex align-center justify-space-between">
                    <CustomDropdownSelect class="mr-4 flex-grow-1" label="Gender" v-model="localPatient.gender" :items="ALL_GENDERS" />

                    <CustomDropdownSelect class="flex-grow-1" label="Pronouns" v-model="localPatient.pronoun" :items="ALL_PRONOUNS" />
                </div>

                <!-- Picker for the preferred patient language -->
                <v-select label="Preferred patient language" v-model="localPatient.preferredLanguage"
                    item-title="display" item-value="code" variant="underlined" color="accent"
                    :menu-props="{ zIndex: 99999 }" :items="ALL_LANGUAGES">
                </v-select>

                <!-- Date of birth picker or age input -->
                <v-text-field v-if="dobShown" label="Date of birth" type="date" color="accent" 
                    v-model="localPatientDOB" variant="underlined" class="dob-input"></v-text-field>
                <button type="button" class="align-self-end text--lighten-1" v-if="dobShown" @click="dobShown = false">
                    <span class="add-btn"> Do not know the dob? Add age </span>
                </button>

                <v-text-field v-if="!dobShown" placeholder="Age" v-model="localPatientAge" class="mt-4" color="accent" variant="underlined" density="compact" type="number">
                    <v-icon class="text-icon" @click="dobShown = true">mdi-calendar</v-icon>
                </v-text-field>
                <button type="button" class="align-self-end text--lighten-1" v-if="!dobShown" @click="dobShown = true">
                    <span class="add-btn"> Do not know the age? Add dob </span>
                </button>

                <!-- Custom picker for the patient ethnicity -->
                <CustomDropdownSelect label="Race/Ethnicity" v-model="localPatient.raceEthnicity" :items="ALL_RACES" hint="Optional"/>

                <!-- Optional text inputs -->
                <v-text-field label="Email" color="accent" class="left-aligned-hint mt-3" v-model="localPatient.email"
                    variant="underlined" hint="Optional" persistent-hint type="email">
                </v-text-field>

                <v-text-field label="Patient Notes" color="accent" class="left-aligned-hint mt-3"
                    v-model="localPatient.diagnosis" variant="underlined" hint="Optional" persistent-hint>
                </v-text-field>

                <!-- Save button -->
                <div class="d-flex justify-center my-6">
                    <v-btn class="mdhub-btn" :loading="loading" @click="savePatientInfo()"
                        elevation="0">Save
                    </v-btn>
                </div>
            </v-form>
        </v-card-text>
    </v-card>    
</template>

<script setup>
import { getFunctions, httpsCallable } from "firebase/functions";
import { Timestamp } from "@firebase/firestore";
import CustomDropdownSelect from '@/components/CustomDropdownSelect.vue';
import { defineProps, getCurrentInstance, onMounted, ref } from 'vue'
import { getPatientFullName } from '@/utilities/patientUtils';
import {
  trackEvent,
  LANGUAGE_SELECTED
} from '@/utilities/analyticsService';

const ALL_GENDERS = ['Male', 'Female', 'Non-binary', 'Prefer not to say', 'Other', 'Unknown'];
const ALL_PRONOUNS = ['he/him', 'she/her', 'they/them'];
const ALL_RACES = [
    'American Indian or Alaska Native', 
    'Asian', 'Black or African American', 
    'Hispanic or Latino', 'Native Hawaiian or Other Pacific Islander', 
    'White', 'Two or More Races', 'Other'
];
const ALL_LANGUAGES = [
    { code: null, display: 'Detect language' },
    { code: 'en-US', display: 'English (US)' },
    { code: 'en', display: 'English (Global)' },
    { code: 'es', display: 'Spanish' },
    { code: 'de', display: 'German' },
    { code: 'fr', display: 'French' },
    { code: 'pt', display: 'Portuguese' },
    { code: 'it', display: 'Italian' },
    { code: 'nl', display: 'Dutch' },
    { code: 'ru', display: 'Russian' },
    { code: 'hi', display: 'Hindi' }
];

const props = defineProps({
    patient: Object
})

const localPatient = ref(null);
const localPatientDOB = ref(null);
const localPatientAge = ref(0);
const dobShown = ref(false);

const loading = ref(false);

onMounted(() => {
    resetInputValues();
})

const { emit } = getCurrentInstance();

const resetInputValues = () => {
    localPatient.value = { ...props.patient };
    localPatientAge.value = localPatient.value.age;

    if (localPatient.value.dob) {
        const dobDate = localPatient.value.dob.toDate();
        const dobDateString = dobDate.toISOString().split('T')[0];
        if (dobDateString) {
            localPatientDOB.value = dobDateString;
        }
    }
}

const closeButtonTap = () => {
    emit('close');
    resetInputValues();
}

const savePatientInfo = async () => {
    if (!localPatient.value) return;
    loading.value = true;
    
    prepareData();
    const data = localPatient.value;

    try {
        console.log('Saving patient data', data);
        const functions = getFunctions();
        const updatePatientCall = httpsCallable(functions, 'updatePatient');
        const res = await updatePatientCall(data);
        console.log('Patient data saved', res);
        emit('close');
    } catch (error) {
        console.log('Error while saving the patient data', error);
    }

    loading.value = false;
}

const prepareData = () => {
    if (localPatient.value.patientFirstName) {
        localPatient.value.patientName = `${localPatient.value.patientFirstName} ${localPatient.value.patientLastName}`.trim();
    }


    if (dobShown.value === true) {
        if (localPatientDOB.value) {
            const localPatientDOBDate = new Date(localPatientDOB.value);
            const localPatientDOBFirestoreTimestamp = Timestamp.fromDate(localPatientDOBDate);

            if (localPatientDOBFirestoreTimestamp) {
                localPatient.value.dob = localPatientDOBFirestoreTimestamp.toDate();
                localPatientAge.value = calculateAge(localPatientDOB.value);
            }
        }
    } else {
        if (localPatientAge.value) {
            localPatient.value.dob = calculateDOBTimestamp(localPatientAge.value).toDate();
        }
    }

    localPatient.value.age = parseInt(localPatientAge.value);

    /// Tracking of the language change
    if (localPatient.value.preferredLanguage !== props.patient.preferredLanguage) {
        trackEvent(LANGUAGE_SELECTED, { language: localPatient.value.preferredLanguage });
    }
}

const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const months = today.getMonth() - birthDate.getMonth();
    if (months < 0 || (months === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }

    return age;
}

const calculateDOBTimestamp = (age) => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const yearOfBirth = currentYear - age;
    const currentDay = today.getDate();
    const currentMonth = today.getMonth();
    const dobDate = new Date(yearOfBirth, currentMonth, currentDay);
    const dobTimestamp = Timestamp.fromDate(dobDate);
    
    return dobTimestamp;
}

</script>

<style scoped>
.align-self-end {
    align-self: self-end !important;
}

.dob-input ::v-deep input {
    display: initial;
}

.add-btn {
    background-color: transparent !important;
    color: var(--bittersweet);
    text-transform: none !important;
    width: auto;
}

.text-icon {
    position: absolute;
    right: 0;
}
</style>