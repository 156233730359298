<template>
    <v-dialog v-model="dialogVisible" fullscreen scrollable persistent>
        <v-card class="onboarding-container">
            <v-container fluid class="fill-height pa-0">
                <v-row no-gutters class="fill-height">
                    <!-- Content container -->
                    <v-col cols="12" md="6" class="content-container">
                        <div class="content-wrapper">
                            <div class="step-content">
                                <h1 class="welcome-title">{{ steps[currentStep].title }}</h1>
                                <h2 class="welcome-subtitle" v-if="steps[currentStep].content">{{
                                    steps[currentStep].content }}</h2>

                                <!-- Welcome/About You - Step 1 -->
                                <div v-if="currentStep === 0" class="form-container">
                                    <div class="form-section">
                                        <h3 class="form-title">What's your position?</h3>
                                        <v-select v-model="selectedPosition" label="Select your position"
                                            variant="outlined" density="comfortable" class="mb-4"
                                            :items="positions"></v-select>
                                    </div>

                                    <div class="form-section">
                                        <h3 class="form-title">What speciality do you practise?</h3>
                                        <v-autocomplete label="Select or type to search" v-model="selectedSpeciality"
                                            variant="outlined" density="comfortable" clearable item-title="speciality"
                                            return-object :items="specialities" class="mb-4"></v-autocomplete>

                                        <v-text-field v-if="selectedSpeciality?.speciality === 'Other'"
                                            label="Please specify" v-model="otherSpeciality" variant="outlined"
                                            density="comfortable" class="mb-4"></v-text-field>
                                    </div>
                                </div>

                                <!-- Your Practice - Step 2 -->
                                <div v-if="currentStep === 1" class="form-container">
                                    <div class="form-section">
                                        <h3 class="form-title">What's your clinic size?</h3>
                                        <v-select v-model="selectedClinicSize" label="Select clinic size"
                                            variant="outlined" density="comfortable" class="mb-4"
                                            :items="clinicSizes"></v-select>
                                    </div>

                                    <div class="form-section">
                                        <h3 class="form-title">How did you find us?</h3>
                                        <v-select v-model="selectedOption" label="Select" variant="outlined"
                                            density="comfortable" class="mb-4" :items="[
                                                'Social Media (e.g., Facebook, Twitter, LinkedIn)',
                                                'Search Engine (e.g., Google, Bing)',
                                                'Recommendation by a colleague or friend',
                                                'Professional Conference or Seminar',
                                                'Community',
                                                'Other (Please specify)'
                                            ]"></v-select>

                                        <v-text-field v-if="selectedOption === 'Other (Please specify)'"
                                            label="Please specify" v-model="otherDetail" variant="outlined"
                                            density="comfortable" class="mb-4"></v-text-field>
                                    </div>
                                </div>

                                <!-- Your Workflow - Step 3 -->
                                <div v-if="currentStep === 2" class="form-container">
                                    <div class="form-section">
                                        <h3 class="form-title">Which EHR system do you use?</h3>
                                        <v-select v-model="selectedEHR" label="Select EHR system" variant="outlined"
                                            density="comfortable" class="mb-4" :items="ehrSystems"></v-select>
                                    </div>

                                    <div class="form-section">
                                        <h3 class="form-title">Your note structure</h3>
                                        <p class="form-subtitle">Confirm your default note structure. You can change and
                                            customize this later.</p>
                                        <v-autocomplete label="Select or type to search" v-model="selectedTemplate"
                                            variant="outlined" density="comfortable" clearable item-title="title"
                                            item-value="templateId" return-object :items="templates" class="mb-4">
                                            <template v-slot:item="{ item, props: slotProps }">
                                                <v-tooltip location="start">
                                                    <template v-slot:activator="{ props: tooltipProps }">
                                                        <v-list-item v-bind="{ ...slotProps, ...tooltipProps }"
                                                            :title="item.title"></v-list-item>
                                                    </template>
                                                    <span>
                                                        <div v-for="(section, index) in item.raw.sections" :key="index"
                                                            class="tooltip-text">
                                                            {{ section }}
                                                        </div>
                                                    </span>
                                                </v-tooltip>
                                            </template>
                                        </v-autocomplete>
                                    </div>
                                </div>

                                <!-- Instructions - Final Step -->
                                <div v-if="currentStep === 3" class="instructions-container">
                                    <p class="instruction-subtitle">{{ steps[currentStep].subContent }}</p>
                                    <v-list class="instruction-list">
                                        <v-list-item v-for="(instruction, index) in steps[currentStep].instructions"
                                            :key="index">
                                            <div>
                                                <div v-if="containsURL(instruction)">
                                                    <span v-html="getInstructionWithLink(instruction)"></span>
                                                </div>
                                                <div v-else class="instruction-text">
                                                    {{ instruction }}
                                                </div>
                                            </div>
                                        </v-list-item>
                                    </v-list>
                                </div>
                            </div>

                            <div class="actions-container">
                                <v-stepper flat alt-labels v-model="currentStep" class="stepper-container">
                                    <v-stepper-header>
                                        <template v-for="(step, index) in steps" :key="index">
                                            <v-divider v-if="index"></v-divider>
                                            <v-stepper-item :value="index" :complete="currentStep > index"
                                                :step="index + 1" :title="step.title">
                                            </v-stepper-item>
                                        </template>
                                    </v-stepper-header>
                                </v-stepper>

                                <div class="buttons-wrapper">
                                    <v-btn v-if="currentStep > 0" variant="text" @click="previousStep" class="back-btn">
                                        {{ previouseButtonText }}
                                    </v-btn>

                                    <div class="action-btns">
                                        <v-btn v-if="currentStep == steps.length - 1" :loading="isPending"
                                            variant="text" class="back-btn" @click="finishStep">
                                            Skip
                                        </v-btn>
                                        <v-btn color="var(--bittersweet)" :loading="isPending" @click="nextStep"
                                            class="action-btn">
                                            <span class="text-button">{{ nextButtonText }}</span>
                                        </v-btn>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </v-col>

                    <!-- Image container -->
                    <v-col cols="12" md="6" class="content-wrapper d-none d-md-block">
                        <div class="image-wrapper">
                            <v-img class="onboarding-image" :src="require('@/assets/woman-onboarding.png')"
                                alt="Banner Image" cover></v-img>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </v-dialog>
</template>


<script setup>


import { ref, watch, defineProps, getCurrentInstance } from 'vue'
import getUser from '@/composables/getUser'
import { trackEvent, NEW_PATIENT_CREATED, USER_SOURCE, DEFAULT_TEMPLATE_CHANGED } from '@/utilities/analyticsService';
import { useRouter } from 'vue-router';
import { getFunctions, httpsCallable } from "firebase/functions";
import getCollectionGlobal from '@/composables/getCollectionGlobal'
import { email } from '@vuelidate/validators';
import { getUserCollection } from '@/composables/userService';

const props = defineProps(
    {
        isModalOpen: {
            type: Boolean,
            default: false
        },
        clinicId: {
            type: String,
            default: ''
        }
    }
)

const { emit } = getCurrentInstance();

const { user } = getUser()
const isPending = ref(false)
const dialogVisible = ref(false);
const otherDetail = ref('');
const router = useRouter();
const selectedOption = ref(null)
const steps = ref([
    {
        title: 'Welcome to mdhub!',
        content: 'Tell us about your role',
        instructions: [],
    },
    {
        title: "Your Practice",
        content: 'Tell us about your practice',
        instructions: [],
    },
    {
        title: "Your Workflow",
        content: 'Help us customize your experience',
        instructions: [],
    },
    {
        title: "Let's get started",
        content: 'To audio record with mobile, download the mdhub app. Alternatively, use our seamless in-browser recording functionality.',
        subContent: 'To get started, create your first recording and review the structure of your note.',
        instructions: [],
    },
])

const currentStep = ref(0);
const nextButtonText = ref('Next');
const previouseButtonText = ref('Back');
const deviceType = ref('Mobile');
const userObject = ref({});

const selectedSpeciality = ref(null)
const selectedTemplate = ref(null)
const otherSpeciality = ref(null)
const selectedPosition = ref(null)
const selectedClinicSize = ref(null)
const selectedEHR = ref(null)

const { documents: masterTemplates } = getCollectionGlobal('master-templates');
const templates = ref([]);

watch(() => masterTemplates.value, (newVal) => {
    if (newVal) {
        templates.value = newVal.map(doc => {
            // Get the sections and sort them by order
            const sections = doc.items ? Object.entries(doc.items)
                .sort((a, b) => (a[1].order || 0) - (b[1].order || 0))
                .map(([name]) => name) : [];

            return {
                title: doc.title,
                templateId: doc.id,
                sections: sections
            };
        });
    }
});

const functions = getFunctions();

const closeModal = () => {
    emit('close-modal');
}

const handleCreatePatient = async (patientName, patientFirstName, patientLastName, gender, diagnosis) => {

    console.log('on handle create patient')

    isPending.value = true

    console.log('SELECTED SPECIALITY', selectedSpeciality.value, otherSpeciality.value)

    const templateId = selectedTemplate.value?.templateId;

    if (selectedSpeciality.value && selectedTemplate.value) {
        if (selectedTemplate.value.templateId !== selectedSpeciality.value.templateId) {
            trackEvent(DEFAULT_TEMPLATE_CHANGED, {
                userId: user.value.uid,
                defaultTemplateId: selectedSpeciality.value.templateId,
                selectedTemplateId: selectedTemplate.value.templateId,
                speciality: selectedSpeciality.value.speciality
            });
        }
    }

    if (templateId) {
        try {

            const setLastTemplateId = httpsCallable(functions, 'setLastTemplateId');
            await setLastTemplateId({ templateId: templateId })

        } catch (error) {
            console.log('error in saving settings', error)
        }
    }

    const createPatient = httpsCallable(functions, 'createPatient');

    const createTestPatient = {
        patientName: patientName,
        patientFirstName: patientFirstName || '',
        patientLastName: patientLastName || '',
        patientDescription: "",
        address: "",
        diagnosis: diagnosis,
        userId: user.value.uid,
        sessions: [],
        gender: gender,
        pronoun: 'she/her',
        dob: '1976-01-01',
    };

    try {

        const { data } = await createPatient(createTestPatient);
        trackEvent(NEW_PATIENT_CREATED, { userId: user.value.uid, userEmail: user.value.email })
        trackEvent(USER_SOURCE, { userId: user.value.uid, userEmail: user.value.email, source: props.selectedOption + ' - ' + props.otherDetail })
        router.push({ name: 'PatientOverview', params: { id: data.id } });
    } catch (error) {
        console.error('error in creating patient', error)

    } finally {
        isPending.value = false
        closeModal();
    }
}

const updateUserSpeciality = async () => {
    const speciality = otherSpeciality.value ? otherSpeciality.value : selectedSpeciality.value?.speciality;
    console.log('speciality', speciality, selectedSpeciality.value, otherSpeciality.value);
    // here we need to update the user speciality 
    const updateUser = httpsCallable(functions, 'updateUser');
    try {
        await updateUser({ speciality: speciality });
    } catch (error) {
        console.log('error in updating user', error);
    }
}

const containsURL = (instruction) => {
    return instruction.includes("http://") || instruction.includes("https://");
}

const extractURL = (instruction) => {
    const regex = /(http[s]?:\/\/[^\s]+)/g;
    const urlMatches = instruction.match(regex);
    return urlMatches ? urlMatches[0] : '';
}

const getInstructionWithLink = (instruction) => {
    const url = extractURL(instruction);
    const linkText = instruction.replace(url, '').trim();
    return `<span>${linkText}</span><a href="${url}" target="_blank">${url}</a>`;
}

const nextStep = async () => {
    let lss = 0
    if (deviceType.value === 'Mobile' || deviceType.value === 'Tablet') {
        lss = steps.value.length - 1
    } else {
        lss = steps.value.length - 2
    }

    if (currentStep.value === lss) {
        currentStep.value = 0;
        nextButtonText.value = 'Next';
        const randomPatient = getRandomPatient();

        // Store all the onboarding information
        try {
            const updateUser = httpsCallable(functions, 'updateUser');
            await updateUser({
                speciality: otherSpeciality.value ? otherSpeciality.value : selectedSpeciality.value?.speciality,
                position: selectedPosition.value,
                clinicSize: selectedClinicSize.value,
                ehrSystem: selectedEHR.value,
                source: selectedOption.value === 'Other (Please specify)' ? otherDetail.value : selectedOption.value,
                onboardingCompleted: true
            });
        } catch (error) {
            console.error('Error updating user profile:', error);
        }

        await handleCreatePatient(randomPatient.name, randomPatient.patientFirstName, randomPatient.patientLastName, randomPatient.gender, randomPatient.diagnosis);
        await addClinicIdToNewUser(user.value.email);
        await updateUserSpeciality();
    } else {
        currentStep.value++;
        if (currentStep.value === lss) {
            nextButtonText.value = 'Create test patient';
        }
    }
}

const finishStep = async () => {
    let lss = 0
    if (deviceType.value === 'Mobile' || deviceType.value === 'Tablet') {
        lss = steps.value.length - 1
    } else {
        lss = steps.value.length - 2
    }

    if (currentStep.value === lss) {
        currentStep.value = 0;
        nextButtonText.value = 'Next';
        closeModal();
        await addClinicIdToNewUser(user.value.email);

        userObject.value = await getUserCollection(user.value.uid);

        await updateUserSpeciality();
    } else {
        currentStep.value++;
        if (currentStep.value === lss) {
            nextButtonText.value = 'Create test patient';
        }
    }
}

const previousStep = () => {
    if (currentStep.value > 0) {
        currentStep.value--;
        nextButtonText.value = 'Next';
    }
}

const getRandomPatient = () => {
    const patients = [
        { name: "Test Patient", patientFirstName: "Test", patientLastName: "Patient", gender: "Male", diagnosis: "Depression" },
        { name: "Test Patient", patientFirstName: "Test", patientLastName: "Patient", gender: "Female", diagnosis: "Anxiety Disorder" },
        { name: "Test Patient", patientFirstName: "Test", patientLastName: "Patient", gender: "Female", diagnosis: "Bipolar Disorder" },
        { name: "Test Patient", patientFirstName: "Test", patientLastName: "Patient", gender: "Male", diagnosis: "Schizophrenia" },
        { name: "Test Patient", patientFirstName: "Test", patientLastName: "Patient", gender: "Female", diagnosis: "Obsessive-Compulsive Disorder" },
        { name: "Test Patient", patientFirstName: "Test", patientLastName: "Patient", gender: "Male", diagnosis: "Post-Traumatic Stress Disorder" },
        { name: "Test Patient", patientFirstName: "Test", patientLastName: "Patient", gender: "Female", diagnosis: "Panic Disorder" },
        { name: "Test Patient", patientFirstName: "Test", patientLastName: "Patient", gender: "Male", diagnosis: "Eating Disorder" },
        { name: "Test Patient", patientFirstName: "Test", patientLastName: "Patient", gender: "Female", diagnosis: "Depression" },
        { name: "Test Patient", patientFirstName: "Test", patientLastName: "Patient", gender: "Male", diagnosis: "Anxiety Disorder" }
    ];

    const randomIndex = Math.floor(Math.random() * patients.length);
    return patients[randomIndex];
};

watch(() => selectedOption, (newVal) => {
    if (newVal !== 'Other (Please specify)') {
        otherDetail.value = ''
    }
})

watch(() => props.isModalOpen, (newVal) => {
    dialogVisible.value = newVal
})

watch(() => dialogVisible.value, (newVal) => {
    if (!newVal) {
        emit('close-modal');
    }
})

watch(() => selectedSpeciality.value, (newVal) => {
    if (newVal) {
        const matchingTemplate = templates.value.find(template => template.templateId === newVal.templateId);
        selectedTemplate.value = matchingTemplate || null;
    }
});

const specialities = [
    { speciality: 'Psychiatry', templateId: 'hgT4EuZcMZKy7QnYLZkg' },
    { speciality: 'Psychology', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
    { speciality: 'Child and Adolescent Psychiatry', templateId: 'hgT4EuZcMZKy7QnYLZkg' },
    { speciality: 'Geriatric Psychiatry', templateId: 'hgT4EuZcMZKy7QnYLZkg' },
    { speciality: 'Forensic Psychiatry', templateId: 'hgT4EuZcMZKy7QnYLZkg' },
    { speciality: 'Addiction Psychiatry', templateId: 'hgT4EuZcMZKy7QnYLZkg' },
    { speciality: 'Consultation-Liaison Psychiatry', templateId: 'hgT4EuZcMZKy7QnYLZkg' },
    { speciality: 'Neuropsychiatry', templateId: 'hgT4EuZcMZKy7QnYLZkg' },
    { speciality: 'Psychosomatic Medicine', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
    { speciality: 'Emergency Psychiatry', templateId: 'hgT4EuZcMZKy7QnYLZkg' },
    { speciality: 'Sleep Medicine', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
    { speciality: 'Clinical Psychology', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
    { speciality: 'Counseling Psychology', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
    { speciality: 'School Psychology', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
    { speciality: 'Industrial-Organizational Psychology', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
    { speciality: 'Health Psychology', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
    { speciality: 'Neuropsychology', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
    { speciality: 'Forensic Psychology', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
    { speciality: 'Sports Psychology', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
    { speciality: 'Developmental Psychology', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
    { speciality: 'Social Psychology', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
    { speciality: 'Experimental Psychology', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
    { speciality: 'Community Psychology', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
    { speciality: 'Anesthesiology', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
    { speciality: 'Cardiology', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
    { speciality: 'Dermatology', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
    { speciality: 'Emergency Medicine', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
    { speciality: 'Endocrinology', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
    { speciality: 'Family Medicine', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
    { speciality: 'Gastroenterology', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
    { speciality: 'General Surgery', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
    { speciality: 'Geriatrics', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
    { speciality: 'Hematology', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
    { speciality: 'Infectious Disease', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
    { speciality: 'Internal Medicine', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
    { speciality: 'Nephrology', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
    { speciality: 'Neurology', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
    { speciality: 'Nutritionist', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
    { speciality: 'Obstetrics and Gynecology', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
    { speciality: 'Oncology', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
    { speciality: 'Ophthalmology', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
    { speciality: 'Orthopedics', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
    { speciality: 'Otolaryngology', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
    { speciality: 'Pediatrics', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
    { speciality: 'Physical Medicine and Rehabilitation', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
    { speciality: 'Plastic Surgery', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
    { speciality: 'Pulmonology', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
    { speciality: 'Radiology', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
    { speciality: 'Rheumatology', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
    { speciality: 'Urology', templateId: 'hed9UvwPtcqnkSFy0qVZ' },
    { speciality: 'Case Manager', templateId: '3an5SD6tH70VKVL58cqp' },
    { speciality: 'Other', templateId: 'hed9UvwPtcqnkSFy0qVZ' }
];

const positions = [
    'Clinic Owner/C-Level',
    'VP/Director',
    'Manager/Admin',
    'Clinician'
]

const clinicSizes = [
    '1-10',
    '10-50',
    '50-100',
    '100-500',
    '500+'
]

const ehrSystems = [
    'Allscripts',
    'Athenahealth',
    'Cerner',
    'DrChrono',
    'eClinicalWorks',
    'Epic',
    'Kareo',
    'Meditech',
    'NextGen Healthcare',
    'Practice Fusion',
    'CareLogic',
    'ICANotes',
    'Osmind',
    'PIMSY',
    'SimplePractice',
    'TheraNest',
    'TherapyNotes',
    'Valant',
    'Other'
];


const addClinicIdToNewUser = async (email) => {
    try {
        const functions = getFunctions();
        const addToClinic = httpsCallable(functions, 'addClinicToUser');
        await addToClinic({ email });
    } catch (error) {
        console.error('Error adding clinic to user:', error);
    }
}

</script>
<style scoped>
.text-button {
    color: white !important;
}

.onboarding-container {
    background-color: #f5f5f5;
}

.content-container {
    height: 100vh;
    overflow-y: auto;
}

.content-wrapper {
    max-width: 640px;
    margin: 0 auto;
    padding: 48px;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.step-content {
    flex: 1;
}

.welcome-title {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 8px;
    color: #1a1f36;
}

.welcome-subtitle {
    font-size: 18px;
    color: #697386;
    margin-bottom: 32px;
    font-weight: 400;
}

.instructions-container {
    margin-bottom: 32px;
}

.instruction-subtitle {
    font-size: 16px;
    color: #697386;
    margin-bottom: 16px;
}

.instruction-list {
    background: transparent;
}

.instruction-text {
    font-size: 16px;
    color: #1f2937;
    line-height: 1.5;
}

.form-container {
    margin-top: 32px;
}

.form-section {
    margin-bottom: 32px;
}

.form-title {
    font-size: 18px;
    font-weight: 500;
    color: #1a1f36;
    margin-bottom: 16px;
}

.form-subtitle {
    font-size: 14px;
    color: #697386;
    margin-bottom: 16px;
}

.actions-container {
    margin-top: auto;
    padding-top: 32px;
}

.stepper-container {
    margin-bottom: 24px;
    background: transparent;
}

.stepper-container :deep(.v-stepper-header) {
    box-shadow: none;
}

.stepper-container :deep(.v-stepper-item) {
    color: #6b7280;
}

.stepper-container :deep(.v-stepper-item .v-stepper-item__title) {
    font-size: 14px;
    color: #6b7280;
    text-align: center;
    margin-top: 8px;
}

.stepper-container :deep(.v-stepper-item .v-stepper-item__step) {
    color: white !important;
}

.stepper-container :deep(.v-stepper-item--complete .v-stepper-item__step) {
    color: white !important;
}

.stepper-container :deep(.v-stepper-item--active) {
    color: var(--bittersweet) !important;
}

.stepper-container :deep(.v-stepper-item--active .v-stepper-item__title) {
    color: var(--bittersweet) !important;
}

.stepper-container :deep(.v-stepper-item--active .v-stepper-item__step) {
    background-color: var(--bittersweet) !important;
}

.stepper-container :deep(.v-stepper-item--complete) {
    color: var(--bittersweet) !important;
}

.stepper-container :deep(.v-stepper-item--complete .v-stepper-item__title) {
    color: #aeb2b9 !important;
}

.stepper-container :deep(.v-stepper-item--complete .v-stepper-item__step) {
    background-color: var(--bittersweet) !important;
}

.buttons-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
}

.action-btns {
    display: flex;
    gap: 12px;
}

.back-btn {
    margin-right: auto;
    color: #6b7280 !important;
    font-weight: 500 !important;
    text-transform: none !important;
    letter-spacing: normal !important;
}

.action-btn {
    height: 48px !important;
    font-weight: 500 !important;
    text-transform: none !important;
    letter-spacing: normal !important;
}

.image-container {
    height: 100vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 48px;
    padding-right: 48px !important;
    padding-left: 48px !important;
}

.onboarding-image {
    height: 85vh;
    width: 100%;
    object-fit: cover;
    border-radius: 24px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

/* Dark theme overrides */
:deep(.v-theme--dark) .onboarding-container {
    background-color: #121212;
}

:deep(.v-theme--dark) .welcome-title {
    color: #ffffff;
}

:deep(.v-theme--dark) .welcome-subtitle,
:deep(.v-theme--dark) .instruction-subtitle {
    color: rgba(255, 255, 255, 0.7);
}

:deep(.v-theme--dark) .instruction-text {
    color: rgba(255, 255, 255, 0.9);
}

:deep(.v-theme--dark) .form-title {
    color: #ffffff;
}

:deep(.v-theme--dark) .form-subtitle {
    color: rgba(255, 255, 255, 0.7);
}

:deep(.v-theme--dark) .back-btn {
    color: rgba(255, 255, 255, 0.7) !important;
}

.image-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tooltip-text {
    color: white;
}

:deep(.v-tooltip .v-overlay__content) {
    background: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 8px 12px;
    border-radius: 4px;
}

/* Add mobile stepper styles */
@media (max-width: 600px) {
    .stepper-container :deep(.v-stepper-item:not(.v-stepper-item--active) .v-stepper-item__title) {
        display: none;
    }

    .stepper-container :deep(.v-stepper-header) {
        justify-content: space-between;
        padding: 0 16px;
    }

    .stepper-container :deep(.v-stepper-item) {
        flex: 0 0 auto;
    }
}
</style>