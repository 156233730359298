<template>
    <v-dialog :model-value="modelValue" @update:model-value="$emit('update:modelValue', $event)" fullscreen
        :scrim="false" transition="dialog-bottom-transition">
        <v-card>
            <v-toolbar dark color="white">
                <v-btn icon dark @click="$emit('update:modelValue', false)">
                    <v-icon color="black">mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title class="text-black">Choose your plan</v-toolbar-title>
            </v-toolbar>

            <div class="pricing-container">
                <!-- Plan toggle -->
                <div class="plan-toggle mb-8">
                    <v-btn-toggle v-model="planType" mandatory rounded="pill" color="primary" class="custom-toggle">
                        <v-btn value="personal" variant="text">Personal</v-btn>
                        <v-btn value="business" variant="text">Clinics</v-btn>
                    </v-btn-toggle>
                </div>

                <!-- Personal Plans -->
                <div v-if="planType === 'personal'" class="plans-grid">
                    <!-- Lite Plan -->
                    <v-card class="plan-card" elevation="0" rounded="lg">
                        <v-card-item>
                            <v-card-title class="text-h5 mb-2">Lite</v-card-title>
                            <div class="price-container">
                                <span class="text-h3">$50</span>
                                <span class="text-body-1 text-medium-emphasis">/month</span>
                            </div>
                            <v-card-text class="text-body-1 mb-4">
                                For part-time clinicians or people who are starting their own practice, with up to 30
                                sessions/month.
                            </v-card-text>
                        </v-card-item>

                        <v-card-text>
                            <v-btn block flat :loading="loadingPlan === 'lite'"
                                :disabled="loadingPlan !== null || currentPlan === 'lite'"
                                :color="currentPlan === 'lite' ? 'transparent' : 'bittersweet'" :class="[
                                    'mb-6',
                                    currentPlan === 'lite' ? 'current-plan-btn' : 'white--text'
                                ]" rounded="lg" @click="selectPlan('lite')">
                                <span :class="currentPlan === 'lite' ? 'text-dark' : 'text-white'">
                                    {{ currentPlan === 'lite' ? 'Current plan' : 'Select plan' }}
                                </span>
                            </v-btn>

                            <div class="features-list">
                                <div class="feature-item" v-for="(feature, index) in litePlanFeatures" :key="index">
                                    <v-icon color="primary" size="small" class="mr-2">mdi-check</v-icon>
                                    {{ feature }}
                                </div>
                            </div>

                            <v-btn v-if="currentPlan === 'lite'" block variant="text" class="mt-4 manage-btn"
                                @click="redirectToCustomerPortal" :loading="portalLoading" :disabled="portalLoading">
                                <v-icon start>mdi-cog</v-icon>
                                Manage my subscription
                            </v-btn>
                        </v-card-text>
                    </v-card>

                    <!-- Pro Plan -->
                    <v-card class="plan-card" elevation="0" rounded="lg">
                        <div class="most-popular-badge">Most Popular</div>
                        <v-card-item>
                            <v-card-title class="text-h5 mb-2">Pro</v-card-title>
                            <div class="price-container">
                                <span class="text-h3">$119</span>
                                <span class="text-body-1 text-medium-emphasis">/month</span>
                            </div>
                            <v-card-text class="text-body-1 mb-4">
                                For full-time clinicians or growing practices with unlimited sessions.
                            </v-card-text>
                        </v-card-item>

                        <v-card-text>
                            <v-btn block flat :loading="loadingPlan === 'pro'"
                                :disabled="loadingPlan !== null || currentPlan === 'pro'" class="red--text"
                                :color="currentPlan === 'pro' ? 'transparent' : 'bittersweet'" :class="[
                                    'mb-6',
                                    currentPlan === 'pro' ? 'current-plan-btn' : 'white--text'
                                ]" rounded="lg" @click="selectPlan('pro')">
                                <span :class="currentPlan === 'pro' ? 'text-dark' : 'text-white'">
                                    {{ currentPlan === 'pro' ? 'Current plan' : 'Select plan' }}
                                </span>


                            </v-btn>

                            <div class="features-list">
                                <div class="feature-item" v-for="(feature, index) in proPlanFeatures" :key="index">
                                    <v-icon color="primary" size="small" class="mr-2">mdi-check</v-icon>
                                    {{ feature }}
                                </div>
                            </div>

                            <v-btn v-if="currentPlan === 'pro'" block variant="text" class="mt-4 manage-btn"
                                @click="redirectToCustomerPortal" :loading="portalLoading" :disabled="portalLoading">
                                <v-icon start>mdi-cog</v-icon>
                                Manage my subscription
                            </v-btn>
                        </v-card-text>
                    </v-card>
                </div>

                <!-- Business Plans -->
                <div v-else class="plans-grid-business">
                    <!-- Team Plan -->
                    <v-card class="plan-card" elevation="0" rounded="lg" border max-width="500px">
                        <v-card-item>
                            <v-card-title class="text-h5 mb-2">Clinic</v-card-title>
                            <div class="price-container">
                                <span class="text-h3">Custom</span>
                                <span class="text-body-1 text-medium-emphasis">/user/month</span>
                            </div>
                            <v-card-text class="text-body-1 mb-4">
                                Power up your clinic with mdhub
                            </v-card-text>
                        </v-card-item>

                        <v-card-text>
                            <v-btn block flat :loading="loadingPlan === 'team'"
                                :disabled="loadingPlan !== null || currentPlan === 'team'"
                                :color="currentPlan === 'team' ? 'transparent' : 'bittersweet'" :class="[
                                    'mb-6 py-6',
                                    currentPlan === 'team' ? 'current-plan-btn' : 'white--text'
                                ]" rounded="lg" @click="selectPlan('team')">
                                <span :class="currentPlan === 'team' ? 'text-dark' : 'text-white'">
                                    {{ currentPlan === 'team' ? 'Current plan' : 'Book a demo' }}
                                </span>
                            </v-btn>

                            <div class="features-list">
                                <div class="feature-item" v-for="(feature, index) in teamPlanFeatures" :key="index">
                                    <v-icon color="primary" size="small" class="mr-2">mdi-check</v-icon>
                                    {{ feature }}
                                </div>
                            </div>

                            <v-btn v-if="currentPlan === 'team'" block variant="text" class="mt-4 manage-btn"
                                @click="redirectToCustomerPortal" :loading="portalLoading" :disabled="portalLoading">
                                <v-icon start>mdi-cog</v-icon>
                                Manage my subscription
                            </v-btn>

                            <div class="mt-8 text-body-2 text-medium-emphasis">
                                For 15+ users, billed annually
                            </div>

                            <div class="mt-8 text-center">
                                <v-icon size="24" class="mr-2">mdi-account-group</v-icon>
                                <div class="text-body-2 mt-2">
                                    Optimize your clinic with mdhub<br>
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                </div>
            </div>
        </v-card>
    </v-dialog>
</template>

<script setup>
import { ref, computed, onMounted, watch, onUnmounted } from 'vue'
import { collection, addDoc, onSnapshot, query, where, getDocs } from 'firebase/firestore'
import { projectFirestore } from '@/firebase/config'
import getUser from '@/composables/getUser'
import { useSubscription } from '@/composables/useSubscription'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { getUserObject } from '@/composables/getUserObject'
import getDocumentOnce from '@/composables/getDocumentOnce'

const props = defineProps({
    modelValue: {
        type: Boolean,
        required: true
    }
})

const emit = defineEmits(['update:modelValue'])

// Method to open the dialog
const openDialog = () => {
    emit('update:modelValue', true)
}

// Make openDialog available to parent components
defineExpose({
    openDialog
})

const { user } = getUser()
const availableProducts = ref(null)
const portalLoading = ref(false)
const isLoading = ref(false)

const currentPlan = computed(() => {
    if (!availableProducts.value?.userProducts?.length) {
        console.log('No subscriptions found, returning free')
        return 'free'
    }

    // Check all user products for their plans
    const userPlans = availableProducts.value.userProducts.map(product => product.metadata?.plan).filter(Boolean)
    console.log('User plans:', userPlans)

    // Return the highest tier plan if user has multiple subscriptions
    if (userPlans.includes('team')) return 'team'
    if (userPlans.includes('pro')) return 'pro'
    if (userPlans.includes('lite')) return 'lite'

    return 'free'
})

const fetchAvailableProducts = async () => {
    if (isLoading.value) return // Prevent concurrent calls

    isLoading.value = true
    try {
        const functions = getFunctions()
        const getProducts = httpsCallable(functions, 'getAvailableProducts')
        const result = await getProducts({ userId: user.value.uid })
        console.log('Available products:', result.data)
        availableProducts.value = result.data
    } catch (error) {
        console.error('Error fetching available products:', error)
    } finally {
        isLoading.value = false
    }
}

// Only fetch products when the dialog opens
watch(() => props.modelValue, async (newValue) => {
    if (newValue && !availableProducts.value) {
        console.log('Dialog opened and products not loaded, fetching products')
        await fetchAvailableProducts()
    }
})

const redirectToCustomerPortal = async () => {
    portalLoading.value = true
    try {
        const functions = getFunctions();
        const functionRef = httpsCallable(
            functions,
            "ext-firestore-stripe-payments-createPortalLink"
        );
        const { data } = await functionRef({
            returnUrl: window.location.origin + "/details",
        });

        window.location.assign(data.url);
    } catch (error) {
        console.error('Error redirecting to customer portal:', error);
    } finally {
        portalLoading.value = false
    }
}

const planType = ref('personal')
const loadingPlan = ref(null)
const products = ref([])

const litePlanFeatures = [
    '30 sessions/month',
    '30 questionnaires/month',
    '30 patients follow-up/month',
    'Unlimited templates',
    'Access to mdhub community',
    'Limited access to new features',
]

const proPlanFeatures = [
    'Unlimited sessions/month',
    'Unlimited questionnaires/month',
    'Unlimited patients follow-up/month',
    'Unlimited templates',
    'Access to mdhub community',
    'Early access to new features',
    'Priority support',
]

const teamPlanFeatures = [

    'Unlimited sessions/month',
    'Unlimited questionnaires/month',
    'Unlimited patients follow-up/month',
    'Unlimited templates',
    'Access to mdhub community',
    'Early access to new features',
    'Priority support',
    'Custom branding options',
    'Advanced analytics and reporting',
    'Users access control',
    'Admin console for clinic management',
    'EHR integration',
]

const createCheckoutSession = async (priceId, plan) => {
    loadingPlan.value = plan
    try {
        const collectionRef = collection(
            projectFirestore,
            "customers",
            user.value.uid,
            "checkout_sessions"
        );

        // check if user has a promo code and the code has an id
        const userObject = await getUserObject();
            let docRef = null;

            if (userObject.promoCode) {
                const { document: code } = await getDocumentOnce('codes', userObject.promoCode);

                if (code.value?.codeId) {
                    docRef = await addDoc(collectionRef, {
                        promotion_code: code.value.codeId,
                        price: priceId,
                        success_url: window.location.origin + "/thank-you",
                        cancel_url: window.location.origin + "/details",
                        allow_promotion_codes: true
                    });
                } else {
                    console.warn('Promo code found but no codeId');
                }
            }

            if (!docRef) {
                docRef = await addDoc(collectionRef, {
                    price: priceId,
                    success_url: window.location.origin + "/thank-you",
                    cancel_url: window.location.origin + "/details",
                    allow_promotion_codes: true
                });
            }


        /*const docRef = await addDoc(collectionRef, {
            price: priceId,
            success_url: window.location.origin + "/thank-you",
            cancel_url: window.location.origin + "/details",
            allow_promotion_codes: true
        });*/

        // Listen for the checkout session
        onSnapshot(docRef, (snap) => {
            const { error, url } = snap.data();
            if (error) {
                console.error(`An error occurred: ${error.message}`);
                loadingPlan.value = null;
            }
            if (url) {
                window.location.assign(url);
            }
        });
    } catch (error) {
        console.error('Error creating checkout session:', error);
        loadingPlan.value = null;
    }
}

const fetchProducts = async () => {
    console.log('Fetching products...');
    const productsRef = collection(projectFirestore, "products");
    const productsQuery = query(productsRef, where("active", "==", true));
    const productsQuerySnap = await getDocs(productsQuery);

    const productsData = [];
    for (const doc of productsQuerySnap.docs) {
        const pricesRef = collection(projectFirestore, "products", doc.id, "prices");
        const pricesQuerySnap = await getDocs(pricesRef);
        const productData = {
            id: doc.id,
            ...doc.data(),
            prices: pricesQuerySnap.docs
                .filter((doc) => doc.data().active)
                .map((price) => ({
                    id: price.id,
                    ...price.data(),
                })),
        };
        productsData.push(productData);
    }
    products.value = productsData;
    console.log('All products loaded:', products.value.length);
}

const selectPlan = async (plan) => {
    console.log('Selecting plan:', plan);

    if (plan === 'team') {
        window.open('https://calendly.com/dm--er0/30min?back=1&month=2025-02', '_blank');
        return;
    }

    // Fetch products if they haven't been loaded yet
    if (products.value.length === 0) {
        await fetchProducts();
    }

    // Find the product with matching plan metadata
    const product = products.value.find(p => p.metadata?.plan === plan);
    console.log('Found product for plan:', plan, product ? {
        id: product.id,
        metadata: product.metadata,
        prices: product.prices.map(p => p.id)
    } : 'No product found');

    if (!product || !product.prices || product.prices.length === 0) {
        console.error('No product or price found for plan:', plan);
        return;
    }

    // Get the first active price for this product
    const priceId = product.prices[0].id;
    console.log(`Creating checkout session for plan ${plan} with price ID:`, priceId);

    await createCheckoutSession(priceId, plan);
}

// Reset loading state when navigating away
const resetLoadingState = () => {
    loadingPlan.value = null;
}

// Add event listener for page unload
onMounted(() => {
    // Only fetch if dialog is initially open
    if (props.modelValue) {
        fetchAvailableProducts()
    }

    window.addEventListener('beforeunload', resetLoadingState);
})

// Cleanup when component is unmounted
onUnmounted(() => {
    resetLoadingState();
    window.removeEventListener('beforeunload', resetLoadingState);
})
</script>

<style scoped>
.pricing-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
}

.plan-toggle {
    display: flex;
    justify-content: center;
}

.custom-toggle {
    background: rgb(243, 244, 246);
}

.plans-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    align-items: start;
    max-width: 1000px;
    margin: 0 auto;
}

.plans-grid-business {
    display: flex;
    justify-content: center;
    align-items: start;
}

.plan-card {
    height: 100%;
    border: 1px solid rgb(229, 231, 235);
}

.price-container {
    margin: 1.5rem 0;
}

.features-list {
    margin-top: 1.5rem;
}

.feature-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1rem;
    color: rgb(55, 65, 81);
    line-height: 1.5;
}

.feature-item .v-icon {
    margin-top: 3px;
}

.most-popular-badge {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #97c8f8;
    color: white;
    padding: 0.25rem 0.5rem;
    border-radius: 0 0 0 0.25rem;
    font-size: 0.75rem;
    font-weight: 600;
}

.current-plan-btn {}

.v-btn .v-btn__content {
    color: #fff !important;
    text-overflow: ellipsis;
    display: inline-block;
    overflow: hidden;
}

.text-dark {
    color: #4f515a !important;
}

.text-white {
    color: white !important;
}

.manage-btn {
    justify-content: flex-start !important;
    padding-left: 0 !important;
    color: #4f515a !important;
}

.manage-btn .v-icon {
    margin-right: 8px;
}
</style>