<template>
  <v-card class="pa-0 mb-4 mt-2" elevation="0" width="100%" height="100%">
    <v-card-title class="headline mt-5 ml-5 left-align">Billing</v-card-title>

    <div v-if="subscription">
      <div class="alert alert-warning" v-if="subscription.cancel_at_period_end">
        This subscription will cancel at the end of the period.
      </div>

      <v-card-text class="headline mt-5 ml-5 mb-5 left-align">
        <div class="d-flex align-center mb-4">
          <v-chip color="primary" class="mr-2">{{ getPlanName() }}</v-chip>
          <!-- <span class="text-subtitle-1">{{ formatPrice() }}</span> -->
        </div>

        <div class="text-subtitle-1 mb-4">
          <v-icon start color="grey">mdi-calendar</v-icon>
          Current period start:
          {{ new Date(subscription.current_period_start.seconds * 1000).toLocaleString() }}
        </div>

        <div class="text-subtitle-1 mb-4">
          <v-icon start color="grey">mdi-calendar-clock</v-icon>
          Renewal Date:
          {{ new Date(subscription.current_period_end.seconds * 1000).toLocaleString() }}
        </div>

        <div class="text-subtitle-1 mb-4">
          <v-icon start :color="getStatusColor()">{{ getStatusIcon() }}</v-icon>
          Status: {{ subscription.status.charAt(0).toUpperCase() + subscription.status.slice(1) }}
        </div>
      </v-card-text>

      <v-btn class="mdhub-btn ml-8 mb-8 left-align-btn" :disabled="isLoading" @click="showPricingDialog" elevation="0">
        {{ isLoading ? "Loading..." : "Billing portal" }}
      </v-btn>
    </div>
    <div v-else>
      <v-card-text class="mb-5"> No subscription yet? Start saving time with mdhub</v-card-text>
    </div>

    <PricingPlans v-model="showDialog" />
  </v-card>
</template>

<script>
import { getFunctions, httpsCallable } from "firebase/functions";
import PriceSelection from "@/components/PriceSelection";
import PricingPlans from "@/components/PricingPlans";
import { ref } from 'vue';

export default {
  props: ["subscription", "userObject"],
  components: { PriceSelection, PricingPlans },
  setup() {
    const showDialog = ref(false);

    return {
      showDialog,
      isLoading: ref(false)
    };
  },
  methods: {
    showPricingDialog() {
      this.showDialog = true;
    },
    getPlanName() {
      console.log('Subscription 1', this.subscription)
      // Get the plan from the items metadata
      const plan = this.subscription?.items?.[0]?.price?.product?.metadata?.plan;
      if (!plan) return 'Unknown Plan';
      return plan.charAt(0).toUpperCase() + plan.slice(1) + ' Plan';
    },
    formatPrice() {
      const price = this.subscription?.items?.[0]?.price;
      if (!price) return '';
      const amount = price.unit_amount / 100;
      const interval = price.recurring?.interval || 'month';
      return `$${amount}/${interval}`;
    },
    getStatusColor() {
      const status = this.subscription?.status;
      switch (status) {
        case 'active':
          return 'success';
        case 'trialing':
          return 'info';
        case 'past_due':
          return 'warning';
        case 'canceled':
          return 'error';
        default:
          return 'grey';
      }
    },
    getStatusIcon() {
      const status = this.subscription?.status;
      switch (status) {
        case 'active':
          return 'mdi-check-circle';
        case 'trialing':
          return 'mdi-clock-outline';
        case 'past_due':
          return 'mdi-alert';
        case 'canceled':
          return 'mdi-close-circle';
        default:
          return 'mdi-help-circle';
      }
    }
  }
}
</script>

<style scoped>
.left-align {
  text-align: left;
}

.left-align-btn {
  display: block;
}

.alert {
  background-color: #fff3cd;
  color: #856404;
  padding: 1rem;
  margin: 1rem;
  border-radius: 4px;
  border: 1px solid #ffeeba;
}

.alert-warning {
  background-color: #fff3cd;
  color: #856404;
}
</style>