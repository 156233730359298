<template>
    <div>
        <!-- Vuetify Circular Progress for Loading Indicator -->
        <v-progress-linear v-if="isLoading" color="electricblue"></v-progress-linear>

        <div v-else-if="!subscription && (!userObject.clinicId || userObject.clinicId === '')" class="pr-5">
            <v-btn class="mr-6 white--text add-meds-btn" @click="showPricingDialog" :loading="isPending"
                variant="outlined">
                <span class="white--text add-meds-btn"> Upgrade </span>
            </v-btn>
        </div>

        <PricingPlans v-model="showDialog" />

        <v-snackbar v-model="snackbar" :timeout="3000" bottom right color="gray" elevation="24" class="text-center">
            {{ snackbarText }}
            <template v-slot:action="{ attrs }">
                <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">Close</v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import { onSnapshot, collection, query, where, getDocs, addDoc } from "firebase/firestore";
import { onMounted, ref, watch } from 'vue';
import getUser from '../composables/getUser'
import { projectFirestore } from '../firebase/config'
import { useSubscription } from '@/composables/useSubscription';
import { getUserCollection } from '@/composables/userService';
import { getUserObject } from '@/composables/getUserObject'
import getDocumentOnce from '@/composables/getDocumentOnce'
import PricingPlans from './PricingPlans.vue';

export default {
    components: {
        PricingPlans
    },
    data() {
        return {
            products: [],
            prices: [],
            selectedPrice: null,
            snackbar: false,
            snackbarText: '',
        };
    },
    props: {
        onUpgradeVisible: {
            type: Function,
            required: true
        }
    },
    setup(props) {
        const { user } = getUser()
        console.log('price button', user.value)
        const { fetchSubscription, subscription, isLoading } = useSubscription(user.value.id);
        const userObject = ref({}); // Initialize userObject as a reactive reference
        const isPending = ref(false)
        const showDialog = ref(false);

        onMounted(() => {
            fetchSubscription();
        });

        const loadUserObject = async () => {
            if (user.value) {
                userObject.value = await getUserCollection(user.value.uid);
            }
        };

        loadUserObject();

        watch([subscription, userObject], (newSubscription, newUserObject) => {
            const upgradeVisible = !subscription.value && (!userObject.value.clinicId || userObject.value.clinicId === '');
            props.onUpgradeVisible(upgradeVisible);
        });

        const showPricingDialog = () => {
            showDialog.value = true;
        };

        return {
            fetchSubscription,
            subscription,
            isLoading,
            userObject,
            isPending,
            showDialog,
            showPricingDialog
        };
    },

    mounted() {
        this.fetchProducts();
    },
    methods: {

        copyPromoCode(promoCode) {
            navigator.clipboard.writeText(promoCode).then(() => {
                this.snackbarText = 'Promo Code Copied!';
                this.snackbar = true;
            }).catch(err => {
                console.error('Could not copy text: ', err);
                this.snackbarText = 'Failed to Copy Promo Code';
                this.snackbar = true;
            });
        },

        getMessage(userObject) {

            if (userObject.promoCode) {
                return `Start with Promo Code ${userObject.promoCode}`
            } else {
                return ''
            }
        },

        getFormattedPrice(price, currency, interval) {

            let priceString = price / 100

            if (currency == "usd") {
                priceString = "$ " + priceString
            } else if (currency == "eur") {
                priceString = "e " + priceString
            }
            priceString = priceString + "/" + interval

            return priceString
        },

        async fetchProducts() {
            const productsRef = collection(projectFirestore, "products");
            const productsQuery = query(productsRef, where("active", "==", true));
            const productsQuerySnap = await getDocs(productsQuery);
            productsQuerySnap.forEach(async (doc) => {
                const pricesRef = collection(projectFirestore, "products", doc.id, "prices");
                const pricesQuerySnap = await getDocs(pricesRef);
                this.products.push({
                    id: doc.id,
                    ...doc.data(),
                    prices: pricesQuerySnap.docs
                        .filter((doc) => doc.data().active)  // Filter out inactive prices
                        .map((price) => {
                            return {
                                id: price.id,
                                ...price.data(),
                            };
                        }),
                });
            });
        },
        async createSub() {

            console.log("window.location.origin: ", window.location.origin)

            this.isPending = true;
            this.selectedPrice = this.products[0].prices[0].id

            const { user } = getUser()
            const collectionRef = collection(
                projectFirestore,
                "customers",
                user.value.uid,
                "checkout_sessions"
            );

            // check if user has a promo code and the code has an id
            const userObject = await getUserObject();
            let docRef = null;

            if (userObject.promoCode) {
                const { document: code } = await getDocumentOnce('codes', userObject.promoCode);

                if (code.value?.codeId) {
                    docRef = await addDoc(collectionRef, {
                        promotion_code: code.value.codeId,
                        price: this.selectedPrice,
                        success_url: window.location.origin + "/details",
                        cancel_url: window.location.origin + "/details",
                        allow_promotion_codes: true
                    });
                } else {
                    console.warn('Promo code found but no codeId');
                }
            }

            if (!docRef) {
                docRef = await addDoc(collectionRef, {
                    price: this.selectedPrice,
                    success_url: window.location.origin + "/details",
                    cancel_url: window.location.origin + "/details",
                    allow_promotion_codes: true
                });
            }

            /*const docRef = await addDoc(collectionRef, {
                price: this.selectedPrice,
                success_url: window.location.origin + "/details",
                cancel_url: window.location.origin + "/details",
                allow_promotion_codes: true
            });*/
            onSnapshot(docRef, (snap) => {
                const { error, url } = snap.data();
                if (error) {
                    console.error(`An error occured: ${error.message}`);
                    this.isPending = false;
                }
                if (url) {
                    window.location.assign(url);
                }
            });
        },
    },
};
</script>
<style scoped>
.white-text-color {
    color: white;
}

.solid-color-container {
    background-color: #e5e5e6;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    letter-spacing: 0.1em;
}

.access-btn {
    margin-top: 15px;
    border-radius: 5px;
}

.clickable {
    cursor: pointer;
}

.upgrade-btn {
    /* background-color: var(--bittersweet);
    color: white !important; */
}

.add-meds-btn {
    background-color: transparent !important;
    color: var(--bittersweet) !important;
}
</style>