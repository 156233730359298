const roundMinutes = (minutes) => {
    const stringMinutesToNumber = +minutes;
    let mappedMinutes = '';
    // we do the below in order to have 15 minutes steps. 
    // So when i click on for example 10:32, it should be 10:30 and not 10:45
    if (stringMinutesToNumber >= 0 && stringMinutesToNumber < 15) {
        mappedMinutes = `00`;
    } else if (stringMinutesToNumber >= 15 && stringMinutesToNumber < 30) {
        mappedMinutes = `15`;
    } else if (stringMinutesToNumber >= 30 && stringMinutesToNumber < 45) {
        mappedMinutes = `30`;
    } else if (stringMinutesToNumber >= 45 && stringMinutesToNumber < 60) {
        mappedMinutes = `45`;
    }

    return mappedMinutes
}

export { roundMinutes };
