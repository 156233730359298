<template>
    <div class="d-flex sms-chat-container">
        <!-- Left sidebar with conversation list -->
        <div class="conversation-list">
            <div class="conversation-list-header d-flex justify-space-between align-center pa-4">
                <h4>Messages</h4>
                <v-btn color="bittersweet" @click="newSmsDialog = true" icon>
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </div>

            <div class="conversation-list-content">


                <v-list v-if="!loadingConversations">
                    <v-list-item v-for="conversation in conversations" :key="conversation.id"
                        :active="selectedConversation?.id === conversation.id" @click="selectConversation(conversation)"
                        class="conversation-item">
                        <v-list-item-title class="d-flex flex-column align-start">
                            <span class="font-weight-medium">{{ conversation.patientName }}</span>
                            <span class="text-caption">{{ conversation.phoneNumber }}</span>
                        </v-list-item-title>
                        <v-list-item-subtitle class="d-flex flex-column align-start">
                            <span class="message-preview">{{ getLastMessage(conversation) }}</span>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="d-flex flex-column">
                            <span class="text-caption text-right">{{ conversation.formattedLastMessageDate }}</span>
                        </v-list-item-subtitle>
                    </v-list-item>
                </v-list>
                <div v-else class="pa-4">
                    <v-progress-circular indeterminate></v-progress-circular>
                </div>
            </div>
        </div>

        <!-- Right side chat interface -->
        <div class="chat-interface">
            <template v-if="selectedConversation">
                <div class="chat-header pa-4">
                    <h5>{{ selectedConversation.patientName }}</h5>
                    <div class="text-caption">{{ selectedConversation.phoneNumber }}</div>
                </div>

                <div class="message-container pa-4" ref="messageContainer">
                    <div v-for="message in selectedConversation.messages" :key="message.timestamp"
                        :class="['message', message.sender === user?.uid || message.sender === 'TASK_AGENT' ? 'outgoing' : 'incoming' || 'external']">
                        <div class="message-bubble">
                            {{ message.text }}
                            <div class="message-timestamp">
                                {{ formatDate(message.timestamp) }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="message-input pa-4">
                    <div class="message-input-container">
                        <v-textarea v-model="newMessage" rows="1" auto-grow dense hide-details
                            placeholder="Type your message..." class="message-input-field"
                            @keydown.enter.prevent="sendMessage"></v-textarea>
                        <v-btn color="bittersweet" :loading="sending" @click="sendMessage" icon class="send-button">
                            <v-icon>mdi-send</v-icon>
                        </v-btn>
                    </div>
                </div>
            </template>

            <div v-else class="no-conversation-selected d-flex align-center justify-center">
                <p>Select a conversation or start a new one</p>
            </div>
        </div>

        <!-- New SMS Dialog -->
        <v-dialog v-model="newSmsDialog" max-width="600px">
            <v-card>
                <v-card-title>New Message</v-card-title>
                <v-tabs v-model="activeTab">
                    <v-tab value="regular">Regular Message</v-tab>
                    <v-tab value="appointment">Appointment Reminder</v-tab>
                </v-tabs>

                <v-card-text>
                    <v-window v-model="activeTab">
                        <!-- Regular Message Tab -->
                        <v-window-item value="regular">
                            <v-form @submit.prevent="startNewConversation">
                                <v-text-field v-model="newSms.phoneNumber" label="Phone Number" required
                                    :rules="[v => !!v || 'Phone number is required']"></v-text-field>
                                <v-textarea v-model="newSms.message" label="Message" required
                                    :rules="[v => !!v || 'Message is required']"></v-textarea>
                            </v-form>
                        </v-window-item>
                        <!-- Appointment Reminder Tab -->
                        <v-window-item value="appointment">
                            <v-form @submit.prevent="startNewConversation">
                                <v-text-field v-model="newSms.phoneNumber" label="Phone Number" required
                                    :rules="[v => !!v || 'Phone number is required']"></v-text-field>

                                <div class="d-flex gap-4">
                                    <!-- Replace the current date picker with this -->
                                    <v-text-field v-model="appointmentDate" type="date" label="Appointment Date"
                                        required class="flex-grow-1"></v-text-field>

                                    <!-- Replace the current time picker with this -->
                                    <v-text-field v-model="appointmentTime" type="time" label="Appointment Time"
                                        required class="flex-grow-1"></v-text-field>
                                </div>

                                <v-textarea v-model="newSms.message" label="Message" required
                                    :rules="[v => !!v || 'Message is required']"
                                    :readonly="activeTab === 'appointment'"></v-textarea>
                            </v-form>
                        </v-window-item>
                    </v-window>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey" text @click="newSmsDialog = false">
                        Cancel
                    </v-btn>
                    <v-btn color="primary" :loading="sending" @click="startNewConversation">
                        Send
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Error Snackbar -->
        <v-snackbar v-model="showError" color="error" timeout="3000">
            {{ errorMessage }}
        </v-snackbar>
    </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue'
import { collection, query, where, onSnapshot, getDocs, and, orderBy } from 'firebase/firestore'
import { db } from '@/firebase/config'
import { getFunctions, httpsCallable } from 'firebase/functions'
import getUser from '@/composables/getUser'
import { getUserObject } from '@/composables/getUserObject'
import { getPatientFullName } from '@/utilities/patientUtils'

export default {
    name: 'SmsChat',

    setup() {
        const { user } = getUser()
        const selectedConversation = ref(null)
        const conversations = ref([])
        const newMessage = ref('')
        const sending = ref(false)
        const messageContainer = ref(null)
        const newSmsDialog = ref(false)
        const loadingConversations = ref(true)
        const showError = ref(false)
        const errorMessage = ref('')
        const userObject = ref(null)
        const newSms = ref({
            phoneNumber: '',
            message: ''
        })
        const activeTab = ref('regular')
        const appointmentDate = ref(new Date().toISOString().split('T')[0])
        const appointmentTime = ref('14:00')


        watch([appointmentDate, appointmentTime, activeTab], () => {
            if (activeTab.value === 'appointment') {
                updateAppointmentMessage()
            }
        })

        const formatAppointmentDateTime = () => {
            if (!appointmentDate.value || !appointmentTime.value) return ''

            const date = new Date(appointmentDate.value + 'T' + appointmentTime.value)

            // Get today and tomorrow dates for comparison
            const today = new Date()
            const tomorrow = new Date(today)
            tomorrow.setDate(tomorrow.getDate() + 1)

            // Compare only the date portions
            const isDateTomorrow = date.toDateString() === tomorrow.toDateString()

            const formattedTime = new Intl.DateTimeFormat('en', {
                hour: 'numeric',
                minute: '2-digit',
                hour12: true
            }).format(date)

            if (isDateTomorrow) {
                return `tomorrow at ${formattedTime}`
            } else {
                const formattedDate = new Intl.DateTimeFormat('en', {
                    month: 'short',
                    day: 'numeric'
                }).format(date)
                return `${formattedDate} at ${formattedTime}`
            }
        }

        const updateAppointmentMessage = () => {
            const dateTimeStr = formatAppointmentDateTime()
            // Check if the dateTimeStr starts with "tomorrow" to determine the correct preposition
            const preposition = dateTimeStr.startsWith('tomorrow') ? '' : 'on '
            newSms.value.message = `Hi, 
I am following up regarding your appointment with Novus ${preposition}${dateTimeStr}. 
Please confirm your appointment by replying "confirm" to this number or calling +1 (931) 820-0242. 
By letting us know if you can or cannot make it you are helping to make sure all patients are getting the care they need. 

If you have any questions or need to reschedule, or cancel your appointment, please call us at +1 (931) 820-0242. 

Thank you! Sarah 

Please review our Attendance Policy here → https://novusbh.com/cancellation`
        }

        const resetNewSmsForm = () => {
            newSms.value = {
                phoneNumber: '',
                message: ''
            }
            activeTab.value = 'regular'
            appointmentDate.value = new Date().toISOString().split('T')[0]
            appointmentTime.value = '14:00'
        }

        watch(newSmsDialog, (newValue) => {
            if (newValue) {
                if (activeTab.value === 'appointment') {
                    updateAppointmentMessage()
                }
            } else {
                resetNewSmsForm()
            }
        })



        const normalizePhoneNumber = (phoneNumber) => {
            return phoneNumber.replace(/\D/g, ''); // Remove all non-digit characters
        }

        const fetchPatientByPhone = async (phoneNumber, clinicId) => {
            try {
                const normalizedPhoneNumber = normalizePhoneNumber(phoneNumber)
                const patientsRef = collection(db, 'patients')
                const q = query(
                    patientsRef,
                    and(
                        where('phoneNumber', '==', normalizedPhoneNumber),
                        where('clinicId', '==', clinicId)
                    )
                )
                const snapshot = await getDocs(q)

                if (!snapshot.empty) {
                    const patientData = snapshot.docs[0].data()
                    return getPatientFullName(patientData) || 'Unknown Patient'
                }
                return null
            } catch (error) {
                console.error('Error fetching patient:', error)
                return null
            }
        }

        const formatLastMessageDate = (timestamp) => {
            if (!timestamp) return ''
            // Check if it's a Firestore Timestamp and convert to milliseconds
            const milliseconds = timestamp.toMillis ? timestamp.toMillis() : timestamp
            return new Intl.DateTimeFormat('en', {
                month: '2-digit',
                day: '2-digit',
                year: '2-digit'
            }).format(new Date(milliseconds))
        }

        // Load conversations from Firestore
        const loadConversations = () => {
            if (!userObject.value?.clinicId) {
                console.log('No clinicId available')
                errorMessage.value = 'Error: No clinic association found'
                showError.value = true
                loadingConversations.value = false
                return
            }

            const smsRef = collection(db, 'sms')
            const q = query(smsRef, where('clinicId', '==', userObject.value?.clinicId), orderBy('lastMessageTimestamp', 'desc'))

            onSnapshot(q, async (snapshot) => {
                try {
                    // Create temporary array to store conversations with patient names
                    const conversationsWithPatients = await Promise.all(
                        snapshot.docs.map(async (doc) => {
                            const conversationData = {
                                id: doc.id,
                                ...doc.data(),
                                messages: doc.data().messages?.sort((a, b) => a.timestamp - b.timestamp) || [],
                                formattedLastMessageDate: formatLastMessageDate(doc.data().lastMessageTimestamp)
                            }

                            // Fetch and add patient name using both phoneNumber and clinicId
                            const patientName = await fetchPatientByPhone(
                                conversationData.phoneNumber,
                                userObject.value.clinicId
                            )
                            return {
                                ...conversationData,
                                patientName: patientName || 'Unknown Patient'
                            }
                        })
                    )

                    conversations.value = conversationsWithPatients
                    console.log('Conversations loaded:', conversations.value)
                } catch (error) {
                    console.error('Error processing conversations:', error)
                    errorMessage.value = 'Error loading patient information'
                    showError.value = true
                } finally {
                    loadingConversations.value = false
                }
            }, (error) => {
                console.error('Error loading conversations:', error)
                errorMessage.value = 'Error loading conversations'
                showError.value = true
                loadingConversations.value = false
            })
        }


        const selectConversation = (conversation) => {
            selectedConversation.value = conversation
            scrollToBottom()
        }

        const startNewConversation = async () => {
            if (!newSms.value.phoneNumber || !newSms.value.message) return
            if (!userObject.value?.clinicId) {
                errorMessage.value = 'Error: No clinic association found'
                showError.value = true
                return
            }

            sending.value = true
            try {
                const functions = getFunctions()
                const sendTextMessage = httpsCallable(functions, 'sendSmsClient')
                await sendTextMessage({
                    to: newSms.value.phoneNumber,
                    text: newSms.value.message,
                    clinicId: userObject.value.clinicId
                })

                // Reset form and close dialog
                newSms.value = { phoneNumber: '', message: '' }
                newSmsDialog.value = false
            } catch (error) {
                console.error('Error sending message:', error)
                errorMessage.value = 'Failed to send message'
                showError.value = true
            } finally {
                sending.value = false
            }
        }

        const sendMessage = async () => {
            if (!newMessage.value.trim() || !selectedConversation.value) return
            if (!userObject.value?.clinicId) {
                errorMessage.value = 'Error: No clinic association found'
                showError.value = true
                return
            }

            sending.value = true
            try {
                const functions = getFunctions()
                const sendTextMessage = httpsCallable(functions, 'sendSmsClient')
                await sendTextMessage({
                    to: selectedConversation.value.phoneNumber,
                    text: newMessage.value,
                    clinicId: userObject.value.clinicId
                })

                newMessage.value = ''
                scrollToBottom()
            } catch (error) {
                console.error('Error sending message:', error)
                errorMessage.value = 'Failed to send message'
                showError.value = true
            } finally {
                sending.value = false
            }
        }

        const scrollToBottom = () => {
            if (messageContainer.value) {
                setTimeout(() => {
                    messageContainer.value.scrollTop = messageContainer.value.scrollHeight
                }, 100)
            }
        }

        const formatDate = (timestamp) => {
            if (!timestamp) return ''
            return new Intl.DateTimeFormat('en', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true
            }).format(new Date(timestamp))
        }

        const getLastMessage = (conversation) => {
            const messages = conversation.messages
            if (!messages || messages.length === 0) return 'No messages'
            return messages[messages.length - 1].text
        }

        onMounted(async () => {
            try {
                console.log('Getting user object...')
                userObject.value = await getUserObject();
                if (userObject.value?.clinicId) {
                    console.log('User object loaded:', userObject.value)
                    loadConversations()
                } else {
                    console.error('No clinic association found')
                    errorMessage.value = 'No clinic association found'
                    showError.value = true
                    loadingConversations.value = false
                }
            } catch (error) {
                console.error('Error getting user object:', error)
                errorMessage.value = 'Error loading user information'
                showError.value = true
                loadingConversations.value = false
            }
        })

        return {
            user,
            selectedConversation,
            conversations,
            newMessage,
            sending,
            messageContainer,
            newSmsDialog,
            newSms,
            loadingConversations,
            showError,
            errorMessage,
            selectConversation,
            sendMessage,
            startNewConversation,
            formatDate,
            getLastMessage,
            formatLastMessageDate,
            activeTab,
            appointmentDate,
            appointmentTime,
        }
    }
}
</script>

<style scoped>
.sms-chat-container {
    height: 92vh;
    display: flex;
    overflow: hidden;
    position: fixed;
    top: 8%;
    left: 4%;
    right: 0;
    bottom: 0;
}


.conversation-list {
    width: 300px;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
}

.conversation-list-header {
    flex-shrink: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    background-color: white;
    z-index: 1;
}

.conversation-list-content {
    flex: 1;
    overflow-y: auto;
    height: calc(92vh - 72px);
}

.chat-interface {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
    overflow: hidden;
    height: 92vh;
}

.message-container {
    flex: 1;
    overflow-y: auto;
    padding: 16px;
    max-height: calc(92vh - 140px);
}


.message {
    margin-bottom: 12px;
    display: flex;
}

.message.incoming {
    justify-content: flex-start;
}

.message.outgoing {
    justify-content: flex-end;
}

.message-bubble {
    max-width: 70%;
    padding: 8px 16px;
    border-radius: 16px;
    position: relative;
}

.message-preview {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
}

.message.incoming .message-bubble {
    background-color: white;
}

.message.outgoing .message-bubble {
    background-color: #0084ff;
    color: white;
}

.message-timestamp {
    font-size: 0.7rem;
    opacity: 0.7;
    margin-top: 4px;
}

.chat-header {
    flex-shrink: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    background-color: white;
    padding: 16px;
    z-index: 1;
}

.message-input {
    flex-shrink: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    background-color: white;
    z-index: 1;
    position: sticky;
    bottom: 0;
}

.message-input-container {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 8px;
}

.message-input-field {
    flex: 1;
    min-width: 0;
}

.send-button {
    flex-shrink: 0;
}


.conversation-item {
    cursor: pointer;
}

.conversation-item:hover {
    background-color: rgba(0, 0, 0, 0.04);
}

.text-right {
    text-align: right;
}
</style>