import { ref } from 'vue'
import { db } from '@/firebase/config'
import { collection, getDocs } from 'firebase/firestore'

const getMasterTemplates = () => {
    const documents = ref([])
    const error = ref(null)

    const load = async () => {
        try {
            const res = await getDocs(collection(db, 'master-templates'))
            
            documents.value = res.docs.map(doc => {
                return { ...doc.data(), id: doc.id }
            })
        } catch (err) {
            console.error('Error getting master templates:', err)
            error.value = 'Could not fetch master templates'
        }
    }

    return { documents, error, load }
}

export default getMasterTemplates 