<template>
  <div class="login-container">
    <div class="auth-header">
      <div></div> <!-- Empty div for flex spacing -->
      <p class="signup-link">
        Already have an account?
        <a href="#" @click.prevent="navigateToLogin">Login</a>
      </p>
    </div>
    <form @submit.prevent="handleSubmit" v-if="!isSignedUp">
      <h1 class="welcome-title">Welcome</h1>
      <h2 class="welcome-subtitle">mdhub clinical AI assistant</h2>

      <div v-if="!isZoom" class="auth-buttons">
        <v-btn class="auth-btn google-btn" @click="handleClick" :loading="isPendingGoogle" elevation="0" block>
          <v-icon left>
            <img src="@/assets/google_icon.svg" alt="Google Icon" />
          </v-icon>
          Continue with Google
        </v-btn>

        <v-btn class="auth-btn microsoft-btn" @click="handleMicrosoftSignUp" :loading="isPendingGoogle" elevation="0"
          block>
          <v-icon left>
            <img src="@/assets/ms-logo.svg" alt="Microsoft Icon" />
          </v-icon>
          Continue with Microsoft
        </v-btn>

        <div class="divider-container">
          <span class="divider-text">or</span>
        </div>
      </div>

      <div class="input-fields">
        <v-text-field type="text" placeholder="Name" v-model="displayName" color="accent" variant="outlined"
          density="comfortable" class="auth-input" required />

        <v-text-field type="email" placeholder="Email" v-model="email" color="accent" variant="outlined"
          density="comfortable" class="auth-input" required />

        <v-text-field :type="show1 ? 'text' : 'password'" placeholder="Password" v-model="password" color="accent"
          variant="outlined" density="comfortable" class="auth-input" required
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" @click:append="toggleShowPassword" />

        <v-text-field v-if="showPromoField" type="text" placeholder="Promo code" v-model="promoCode" color="accent"
          variant="outlined" density="comfortable" class="auth-input" append-inner-icon="mdi-close"
          @click:append-inner="showPromoField = false" />
      </div>

      <ul v-if="showRules" class="rules-list">
        <li :class="{ 'rule-met': hasLetter, 'rule-not-met': !hasLetter && highlightRules }">Contains a letter</li>
        <li :class="{ 'rule-met': hasCapital, 'rule-not-met': !hasCapital && highlightRules }">Contains a capital letter
        </li>
        <li :class="{ 'rule-met': hasNumber, 'rule-not-met': !hasNumber && highlightRules }">Contains a number</li>
        <li :class="{ 'rule-met': hasSpecial, 'rule-not-met': !hasSpecial && highlightRules }">Contains a special
          character</li>
        <li :class="{ 'rule-met': isMinLength, 'rule-not-met': !isMinLength && highlightRules }">Is at least 12
          characters long</li>
      </ul>

      <div v-if="error" class="error-message">{{ error }}</div>

      <v-tooltip v-if="!showPromoField" text="Don't have a promo code? No worries, you can enter it later!"
        location="end">
        <template v-slot:activator="{ props }">
          <button v-if="!showPromoField" @click="showPromoField = true" v-bind="props" class="promo-button">
            Do you have a promo code?
          </button>
        </template>
      </v-tooltip>

      <v-checkbox v-model="checkbox" class="agree-check terms-checkbox">
        <template v-slot:label>
          <div class="checkbox-label">
            I agree to mdhub's
            <a href="https://mdhub.ai/terms-of-service" target="_blank" @click.stop>Terms of Service</a>,
            <a href="https://mdhub.ai/business-associate-agreement" target="_blank" @click.stop>Business Associate
              Agreement</a>
            and acknowledge the
            <a href="https://mdhub.ai/privacy-policy" target="_blank" @click.stop>Privacy Policy</a>
          </div>
        </template>
      </v-checkbox>

      <v-btn class="submit-btn" elevation="0" :loading="isPending" type="submit" :disabled="!checkbox" block>
        <span class="submit-btn-text">Sign up</span>
      </v-btn>
    </form>
    <div v-else class="d-flex justify-center">
      <SavingSpinner label="Preparing your account please wait" style="position:relative;" />
    </div>
  </div>
</template>

<script>

import useSignup from '@/composables/useSignup'
import useSignupGoogle from '@/composables/useSignupGoogle'
import useSignupMicrosoft from '@/composables/useSignupMicrosoft'
import useCollection from '@/composables/useCollection'
import { ref, computed, getCurrentInstance, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { sendEmailVerification } from 'firebase/auth';
import { timestamp } from '@/firebase/config'
import { checkUserExists } from '@/composables/userService';
import { trackEvent, NEW_USER_SIGNUP, NEW_USER_SIGNUP_FAILED } from '@/utilities/analyticsService';
import { getIsZoomApp } from '@/composables/configureZoom'
import { getFunctions, httpsCallable } from "firebase/functions";
import SavingSpinner from '@/components/SavingSpinner.vue'

export default {
  components: { SavingSpinner },
  mounted() {
    this.setMetaTags();
  },
  methods: {
    setMetaTags() {
      document.title = "mdhub Signup - Access the AI Scribe for Behavioral Health Clinicians";
      let metaDescription = document.querySelector('meta[name="description"]');
      if (!metaDescription) {
        metaDescription = document.createElement('meta');
        metaDescription.setAttribute('name', 'description');
        document.getElementsByTagName('head')[0].appendChild(metaDescription);
      }
      metaDescription.setAttribute('content', 'Signup to mdhub, the #1 AI assistant for mental health care clinicians. Experience seamless, secure, and efficient AI assistant that you can fully focus on patient care');
    },
    toggleShowPassword() {
      this.show1 = !this.show1;
    },
    navigateToLogin() {
      this.$router.push({ name: 'Login' });
    }
  },
  setup() {
    const { err, addDoc } = useCollection('user')
    const { error, signup, isPending } = useSignup();
    const { errorGoogle, signupGoogle, isPendingGoogle } = useSignupGoogle();
    const { errorMicrosoft, signupMicrosoft, isPendingMs } = useSignupMicrosoft();
    const router = useRouter();
    const email = ref("");
    const password = ref("");
    const displayName = ref("");
    const instance = getCurrentInstance();
    const promoCode = ref("");
    const showPromoField = ref(false)
    const highlightRules = ref(false);
    const show1 = ref(false);
    const agreeCheck = ref()
    const checkbox = ref(false);
    const { isZoomApp } = getIsZoomApp();
    const isZoom = isZoomApp.value;

    const isSignedUp = ref(false);

    const referralCode = router.currentRoute.value.query.code;
    instance.appContext.config.globalProperties.$gtm.push({ event: 'signup ' });

    if (referralCode) {
      showPromoField.value = true
      promoCode.value = referralCode
    }

    const hasLetter = computed(() => /[a-z]/.test(password.value));
    const hasCapital = computed(() => /[A-Z]/.test(password.value));
    const hasNumber = computed(() => /[0-9]/.test(password.value));
    const hasSpecial = computed(() => /[\W_]/.test(password.value));
    const isMinLength = computed(() => password.value.length >= 12);

    const showRules = computed(() => password.value.length > 0);

    const passwordStrength = computed(() => {
      let score = 0;
      if (isMinLength.value) score++;
      if (hasLetter.value) score++;
      if (hasCapital.value) score++;
      if (hasNumber.value) score++;
      if (hasSpecial.value) score++;
      return score;
    });

    const handleSubmit = async () => {
      highlightRules.value = true; // Highlight the rules that are not met
      isPending.value = true;

      const strength = passwordStrength.value;
      if (strength < 5) {
        error.value = "Please meet the requirements from above."
        isPending.value = false;
      } else {
        try {
          const res = await signup(email.value, password.value, displayName.value);
          await sendEmailVerification(res.user);
          await addDoc({
            email: email.value.toLowerCase(),
            name: displayName.value,
            userId: res.user.uid,
            promoCode: promoCode.value,
            createdAt: timestamp()
          })

          if (!error.value) {
            isSignedUp.value = true;
            trackEvent(NEW_USER_SIGNUP, { userId: res.user.uid, userEmail: email.value, method: "Email/Pass" })
            //await addClinicIdToNewUser(res.user.email);
            instance.appContext.config.globalProperties.$gtm.push({ event: 'newUser ' });
            isPending.value = false;
            redirectToPatientsPage();
          }
        } catch (error) {
          isPending.value = false;
          isSignedUp.value = false;
          trackEvent(NEW_USER_SIGNUP_FAILED, { userId: '-', userEmail: email.value, error, method: "Email/Pass" })
        }
      }
    };

    const redirectToPatientsPage = () => {
      router.push({
        name: "UserPatients", query: { newUser: 'true' }
      });;
    }

    const handleMicrosoftSignUp = async () => {
      const res = await signupMicrosoft();
      await createUserAndNavigate(res)
    };

    const handleClick = async () => {
      const res = await signupGoogle();
      await createUserAndNavigate(res)
    };

    const addClinicIdToNewUser = async (email) => {
      try {
        const functions = getFunctions();
        const addToClinic = httpsCallable(functions, 'addClinicToUser');
        await addToClinic({ email });
      } catch (error) {
        console.error('Error adding clinic to user:', error);
      }

    }

    async function createUserAndNavigate(res) {
      const userExists = await checkUserExists(res?.user.uid);
      if (!userExists) {
        await addDoc({
          email: res.user.email.toLowerCase(),
          name: res.user.displayName,
          userId: res.user.uid,
          speciality: "",
          promoCode: promoCode.value,
          createdAt: timestamp()
        })
      }

      if (!error.value) {
        trackEvent(NEW_USER_SIGNUP, { userId: res.user.uid, userEmail: res.user.email, method: "Google" })
        // assuming that the user has been created 
        //await addClinicIdToNewUser(res.user.email);
        router.push({ name: "UserPatients", query: { newUser: 'true' } });
      }
    }

    return {
      isZoom,
      email, password, displayName, handleSubmit,
      handleMicrosoftSignUp,
      error, isPending, errorGoogle, isPendingGoogle, handleClick, promoCode, showPromoField,
      hasLetter, hasCapital, hasNumber, hasSpecial, isMinLength, highlightRules, showRules, show1, checkbox,
      isSignedUp
    };
  }
}
</script>

<style scoped>
.login-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1px;
}

.auth-header {
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1px;
  padding: 0 24px;
}

form {
  width: 100%;
  max-width: 480px;
  padding: 48px;
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.welcome-title {
  font-size: 48px;
  font-weight: 600;
  margin-bottom: 8px;
  text-align: left;
  color: #1a1f36;
}

.welcome-subtitle {
  font-size: 24px;
  color: #697386;
  margin-bottom: 32px;
  font-weight: 400;
  text-align: left;
}

.auth-buttons {
  margin-bottom: 32px;
}

.auth-btn {
  margin-bottom: 16px;
  height: 48px !important;
  border: 1px solid #e5e7eb !important;
  background-color: white !important;
  color: #1f2937 !important;
  text-transform: none !important;
  letter-spacing: normal !important;
  font-weight: 500 !important;
  border-radius: 8px !important;
  font-size: 14px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.auth-btn :deep(.v-icon) {
  margin-right: 8px !important;
  height: 20px !important;
  width: 20px !important;
}

.auth-btn img {
  height: 20px !important;
  width: 20px !important;
  object-fit: contain;
}

.divider-container {
  position: relative;
  text-align: center;
  margin: 24px 0;
}

.divider-container::before,
.divider-container::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 45%;
  height: 1px;
  background-color: #e5e7eb;
}

.divider-container::before {
  left: 0;
}

.divider-container::after {
  right: 0;
}

.divider-text {
  background-color: white;
  padding: 0 16px;
  color: #6b7280;
}

.auth-input {
  margin-bottom: 16px;
}

.error-message {
  color: #dc2626;
  margin-bottom: 16px;
  font-size: 14px;
}

.rules-list {
  list-style: none;
  padding: 0;
  margin: 0 0 16px 0;
  font-size: 14px;
}

.rule-met {
  color: #059669;
  margin-bottom: 4px;
}

.rule-not-met {
  color: #dc2626;
  margin-bottom: 4px;
}

.promo-button {
  background: none;
  border: none;
  color: #6b7280;
  font-size: 14px;
  cursor: pointer;
  padding: 0;
  margin-bottom: 16px;
  text-decoration: underline;
}

.terms-checkbox {
  margin-bottom: 16px;
}

.checkbox-label {
  font-size: 14px;
  color: #6b7280;
}

.checkbox-label a {
  color: #4f46e5;
  text-decoration: none;
}

.submit-btn {
  margin-bottom: 0;
  height: 48px !important;
  background-color: var(--bittersweet) !important;
  color: white !important;
  text-transform: none !important;
  letter-spacing: normal !important;
  font-weight: 500 !important;
  border-radius: 8px !important;
}

.submit-btn-text {
  color: white !important;
}

.signup-link {
  font-size: 14px;
  color: #6b7280;
}

.signup-link a {
  color: #4f46e5;
  text-decoration: none;
  font-weight: 500;
}

/* Dark theme overrides */
:deep(.v-theme--dark) {
  background-color: #121212;
}

:deep(.v-theme--dark) form {
  background-color: #1e1e1e;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.2);
}

:deep(.v-theme--dark) .welcome-title {
  color: #ffffff;
}

:deep(.v-theme--dark) .welcome-subtitle {
  color: rgba(255, 255, 255, 0.7);
}

:deep(.v-theme--dark) .auth-btn {
  background-color: rgba(255, 255, 255, 0.05) !important;
  border-color: rgba(255, 255, 255, 0.12) !important;
  color: white !important;
}

:deep(.v-theme--dark) .divider-text {
  background-color: #1e1e1e;
  color: rgba(255, 255, 255, 0.6);
}

:deep(.v-theme--dark) .divider-container::before,
:deep(.v-theme--dark) .divider-container::after {
  background-color: rgba(255, 255, 255, 0.12);
}

:deep(.v-theme--dark) .checkbox-label,
:deep(.v-theme--dark) .promo-button {
  color: rgba(255, 255, 255, 0.7);
}

:deep(.v-theme--dark) .checkbox-label a {
  color: #818cf8;
}

:deep(.v-theme--dark) .signup-link {
  color: rgba(255, 255, 255, 0.7);
}

:deep(.v-theme--dark) .signup-link a {
  color: #818cf8;
}
</style>
