    <template>
        <div class="error" v-if="error">{{ error }}</div>
        <div class="breadcrumb">
            <v-breadcrumbs :items="navigation">
                <template v-slot:divider>
                    <v-icon icon="mdi-chevron-right"></v-icon>
                </template>
            </v-breadcrumbs>
        </div>
        <div v-if="session" class="session-details">

            <div>
                <SessionInput :patientId="patientId" :id="id" @modality-changed="updateSessionModality"
                    @time-changed="updateTime" />
            </div>

            <div class="note-container summary-border">
                <v-toolbar color="white" class="container-tab">
                    <template v-slot:default>
                        <div class="tabs-container">
                            <v-tabs v-model="model" centered class="patient-chart-options"
                                slider-color="var(--bittersweet)">
                                <v-tab>Clinical Note</v-tab>
                                <v-tab :disabled="session.state !== 'completed'"
                                    @click="createPatientInstructions(false)">
                                    Patient Communication
                                </v-tab>
                            </v-tabs>
                        </div>
                    </template>

                </v-toolbar>
                <v-container fluid fill-height class='bg-white'>
                    <v-window v-model="model" height="100%">
                        <v-window-item v-if="clinical_note">
                            <v-card>
                                <div class="d-flex flex-column">
                                    <div class="summary-copy d-flex flex-row-reverse align-center">
                                        <div class="d-flex flex-row-reverse align-center">
                                            <v-tooltip text="Notes settings" location="bottom">
                                                <template v-slot:activator="{ props }">
                                                    <v-btn icon v-bind="props" elevation="0" variant="text"
                                                        @click="openMenu"
                                                        :disabled="userActionDisabled(templateSettingsUserAction)">
                                                        <v-icon color="grey">mdi-cog-outline</v-icon>
                                                    </v-btn>
                                                </template>
                                            </v-tooltip>

                                            <v-tooltip v-if="isEhrExtension || isRula" text="Send note to EHR"
                                                location="bottom">
                                                <template v-slot:activator="{ props }">
                                                    <v-btn v-bind="props" elevation="0" variant="text"
                                                        @click="triggerSendEhr"
                                                        :disabled="userActionDisabled(summaryEditUserAction)">
                                                        <v-icon color="grey">mdi-send-outline</v-icon>
                                                        <span style="margin-left: 4px;">EHR</span>
                                                    </v-btn>
                                                </template>
                                            </v-tooltip>


                                            <v-tooltip location="bottom">
                                                <template v-slot:activator="{ props, on }">
                                                    <v-btn class="mr-2" icon @click="searchingNotes = !searchingNotes"
                                                        v-bind="props" v-on="on" elevation="0" variant="text"
                                                        :disabled="userActionDisabled(summaryEditUserAction)">
                                                        <v-icon color="grey">mdi-find-replace</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Search and replace text (case sensitive)</span>
                                            </v-tooltip>

                                            <div class="mr-2" id="template-menu">
                                                <v-menu>
                                                    <template v-slot:activator="{ props }">
                                                        <v-btn class="mdhub-btn" elevation="0" v-bind="props"
                                                            :loading="isPending"
                                                            :disabled="userActionDisabled(templateSettingsUserAction)"
                                                            append-icon="mdi-chevron-down">
                                                            Re-generate note
                                                        </v-btn>
                                                    </template>

                                                    <v-list style="cursor: pointer">
                                                        <v-list-item v-for="(item, index) in items     " :key="index"
                                                            @click="selectTemplate(item)"
                                                            :class="{ 'template-item': true, 'last-item': index === items.length - 1 }"
                                                            :style="index === items.length - 1 ? { 'background-color': '#7ce2e7', 'color': '#071022' } : {}">

                                                            <v-list-item-title
                                                                :style="{ color: index === items.length - 1 ? '#071022' : '' }">
                                                                {{
                                                                    item.templateHeader
                                                                }}</v-list-item-title>
                                                            <v-tooltip activator="parent" location="end">
                                                                <span
                                                                    v-html="getDescriptionWithLineBreaks(item.template)"></span>
                                                            </v-tooltip>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-menu>
                                            </div>
                                            <v-tooltip text="Copy note to clipboard" location="bottom">
                                                <template v-slot:activator="{ props }">
                                                    <v-btn v-bind="props" elevation="0" variant="text"
                                                        @click="triggerCopy"
                                                        :disabled="userActionDisabled(summaryEditUserAction)">
                                                        <v-icon color="grey">mdi-content-copy</v-icon>
                                                        <span style="margin-left: 4px;">Copy Note</span>
                                                    </v-btn>
                                                </template>
                                            </v-tooltip>
                                            <v-tooltip v-if="editViewNote"
                                                :text="editing ? 'Click to view note' : 'Click to be able to edit note'"
                                                location="bottom">
                                                <template v-slot:activator="{ props }">
                                                    <v-btn v-bind="props" elevation="0" variant="text"
                                                        @click="switchEditing">
                                                        <v-icon v-if="editing"
                                                            color="bittersweet">mdi-eye-outline</v-icon>
                                                        <v-icon v-else color="bittersweet">mdi-pencil-outline</v-icon>
                                                        <span style="margin-left: 4px; color:var(--bittersweet)">{{
                                                            editing
                                                                ? 'View' : 'Edit' }}
                                                            Note</span>
                                                    </v-btn>
                                                </template>
                                            </v-tooltip>
                                        </div>
                                        <div class="d-flex checkSession">
                                            <v-checkbox @update:modelValue="handleSaveDebounced"
                                                v-model="statusCheckbox" class="d-flex justify-left ml-0"></v-checkbox>
                                            <span class="truncate">{{ notesStatus }}</span>
                                        </div>
                                    </div>
                                    <div class="mr-5" style="font-size: 14px; color: grey; text-align: right;">
                                        {{ !!currentTemplate ? currentTemplate : selectedTemplate }}
                                    </div>




                                    <SessionNote v-if="!canUseNewClinicalNote" :patientId="patientId" :id="id"
                                        :copyTrigger="copyTrigger" :searching="searchingNotes" :userId="user.uid"
                                        :userEmail="user.email" :sessionId="sessionId" @close-search="closeSearch"
                                        :disabledCopy="disabledCopy" @show-price-banner="openPriceDialog">
                                    </SessionNote>

                                    <SessionNoteWithBlocks v-else :id="id" :copyTrigger="copyTrigger" :sendEhr="sendEhr"
                                        :searching="searchingNotes" :patientId="patientId" :userId="user.uid"
                                        :userEmail="user.email" :modality="modality" :sessionStart="start"
                                        :sessionEnd="end" :sessionDate="date" :sessionId="sessionId"
                                        @close-search="closeSearch"
                                        @show-old-clinical-note="canUseNewClinicalNote = false"
                                        :disabledCopy="disabledCopy" @show-price-banner="openPriceDialog"
                                        :editing="editing" :templateChanged="templateChanged">
                                    </SessionNoteWithBlocks>
                                </div>
                            </v-card>
                        </v-window-item>
                        <v-window-item v-if="instructions">
                            <v-card>
                                <div class="summary-copy d-flex flex-row-reverse align-center justify-space-between">
                                    <div>
                                        <v-tooltip location="bottom">
                                            <template v-slot:activator="{ props, on }">
                                                <v-btn class="mr-2" icon @click="copyToClipboard(title.instructions)"
                                                    v-bind="props" v-on="on" elevation="0" variant="text">
                                                    <v-icon color="grey">mdi-content-copy</v-icon>
                                                </v-btn>

                                            </template>
                                            <span>Copy to clipboard</span>
                                        </v-tooltip>

                                        <v-tooltip location="bottom">
                                            <template v-slot:activator="{ props, on }">
                                                <v-btn class="mr-2" icon @click="createPatientInstructions(true)"
                                                    v-bind="props" v-on="on" elevation="0" variant="text">
                                                    <v-icon color="grey">mdi-refresh</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Generate new Instructions</span>
                                        </v-tooltip>
                                        <v-tooltip location="bottom">
                                            <template v-slot:activator="{ props, on }">
                                                <v-btn class="mr-2" icon @click="searching = !searching" v-bind="props"
                                                    v-on="on" elevation="0" variant="text">
                                                    <v-icon color="grey">mdi-find-replace</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Search and replace text (case sensitive)</span>
                                        </v-tooltip>
                                        <v-tooltip location="bottom">
                                            <template v-slot:activator="{ props, on }">

                                                <v-btn color="#FF7260" dark class="mdhub-btn mr-2"
                                                    :disabled="disableInstructionsEmail" @click="isSendingEmail = true"
                                                    v-bind="props" v-on="on" elevation="0">
                                                    <v-icon class="mr-3" style="color: white;">mdi-email</v-icon>
                                                    Secure send
                                                </v-btn>

                                            </template>
                                            <span>HIPAA secure email</span>
                                        </v-tooltip>
                                    </div>
                                    <v-select label="Language" class="mr-2" style="max-width: 150px;" bg-color="#f1f1f3"
                                        variant="solo-filled" flat hide-details v-model="instrutionsLanguage"
                                        :items="['English', 'Spanish', 'French', 'German', 'Arabic', 'Chinese', 'Punjabi', 'Tagalog', 'Russian']"></v-select>

                                </div>
                                <div>
                                    <v-progress-linear v-if="isPendingSave" indeterminate
                                        color="primary"></v-progress-linear>
                                </div>
                                <div class="search-replace-wrapper mt-4">
                                    <v-card v-if="searching" class="search-replace-card" elevation="0">
                                        <!-- <v-text-field label="Search" v-model="searchModel"></v-text-field> -->
                                        <v-text-field class=" ml-6" v-model="searchModel" label="Search" color="accent"
                                            variant="underlined" density="compact" clearable flat></v-text-field>
                                        <!-- <v-text-field label="Replace" v-model="replaceModel"></v-text-field> -->
                                        <v-text-field class="ml-6" v-model="replaceModel" label="Replace with"
                                            color="accent" variant="underlined" density="compact" clearable
                                            flat></v-text-field>
                                        <template v-slot:actions>
                                            <v-btn @click="searchAndReplace" class="ml-2 mr-2">
                                                Replace
                                            </v-btn>
                                            <v-btn icon @click="searching = false">
                                                <v-icon>mdi-close</v-icon>
                                            </v-btn>
                                        </template>
                                    </v-card>
                                </div>
                                <v-textarea v-if="session.instructions" class="text-center rounded-b-xl pa-8"
                                    variant="plain" rows="1" auto-grow v-model="instructionsValue" no-resize
                                    @update:model-value="instructionsUpdated = true" @blur="handleSaveOnBlur" />

                            </v-card>
                        </v-window-item>
                    </v-window>
                </v-container>
            </div>
        </div>

        <v-snackbar v-model="snackbar" :timeout="2000" color="gray">
            {{ copyText }}

            <template v-slot:actions>
                <v-btn color="pink" variant="text" @click="snackbar = false">
                    Close
                </v-btn>
            </template>
        </v-snackbar>
        <v-snackbar v-model="snackbarNotes" :timeout="2000" color="gray">
            Note marked as {{ notesStatus }}
        </v-snackbar>
        <v-snackbar v-model="isPendingSave" :timeout="1000" color="gray">
            Autosaving instructions
            <template v-slot:actions>
                <v-btn icon @click="isPendingSave = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
        <Feedback :userEmail="user.email" />
        <DialogTemplate :isModalOpen="isModalOpen" @close-modal="closeModal" />
        <v-dialog v-model="isDialogOpen" persistent max-width="600px">
            <UserSettings @close-dialog="closeUserSettingsDialog" :dialog="true"
                :userSettings="userObject?.settings ?? {}" :userId="userObject?.id" />
        </v-dialog>
        <v-dialog v-model="isSendingEmail" persistent max-width="800px">
            <SessionInstructionsEmail @close-email="isSendingEmail = false" :emailBody="session.instructions"
                :patientId="patientId" :sessionDate="session.date" :sessionId="sessionId" :userEmail="user.email" />
        </v-dialog>

        <v-dialog width="800" v-model="priceDialog">
            <PriceSelection :details="false"></PriceSelection>
        </v-dialog>
    </template>

<script>


import getDocument from '@/composables/getDocument'
import useDocument from '@/composables/useDocument'
import getAudio from '@/composables/getAudio'
import { ref, watch, onMounted, computed } from 'vue'
import "vue3-timepicker/dist/VueTimepicker.css";
import WordHighlighter from 'vue-word-highlighter';
import getUser from '@/composables/getUser'
import getCollection from '@/composables/getCollection'
import getCollectionGlobal from '@/composables/getCollectionGlobal'
import ModalTemplate from '@/components/ModalTemplate.vue';
import DialogTemplate from '@/components/DialogTemplate.vue';
import SessionNote from '@/components/SessionNote.vue';
import SessionInput from '@/components/SessionInput.vue';
import Feedback from '@/components/Feedback.vue';
import { getFunctions, httpsCallable } from "firebase/functions";
import useCollection from '@/composables/useCollection'
import { debounce } from 'lodash';
import VueGoogleAutocomplete from "vue-google-autocomplete";
import SessionInstructionsEmail from '@/views/sessions/SessionInstructionsEmail.vue';
import { trackEvent, CREATE_PATIENT_INSTRUCTIONS, COPY_DISABLED, NOTE_STATUS_UPDATE, NOTE_STATUS_UPDATE_FAILURE, CLINICAL_TEMPLATE_SELECTED, INSTRUCTIONS_SEARCH_AND_REPLACE } from '@/utilities/analyticsService';
import { getUserActionState, UserActions, SessionState } from '@/composables/getUserActionState';
import SessionNoteWithBlocks from '@/components/SessionNoteWithBlocks.vue';
import { newClinicalNoteWhiteListedAccounts } from '@/composables/isWhitelistedAccount'
import { useBlockedUsers } from '@/composables/useSubscription';
import { getUserCollection } from '@/composables/userService';
import { useSubscription } from '@/composables/useSubscription';
import PriceSelection from '@/components/PriceSelection.vue';
import { ehrExtensionWhitelist, editViewNoteWhitelist, ehrExtensionWhitelistRula } from '@/composables/isWhitelistedAccount'
import UserSettings from '../user/UserSettings.vue';
import { getPatientFullName } from '@/utilities/patientUtils';

export default {
    props: ['patientId', 'id'],
    components: {
        WordHighlighter,
        ModalTemplate,
        DialogTemplate,
        Feedback,
        VueGoogleAutocomplete,
        SessionNote,
        SessionNoteWithBlocks,
        SessionInput,
        SessionInstructionsEmail,
        PriceSelection,
        UserSettings
    },
    computed: {
        textareaValue: {
            get() {
                return this.session.summary;
            },
            set(newValue) {
                this.session.summary = newValue;
            }
        },
        instructionsValue: {
            get() {
                return this.session.instructions;
            },
            set(newValue) {
                this.instructionsUpdated = true
                this.session.instructions = newValue;
            }
        },
        combinedArrayElements() {
            if (Array.isArray(this.value)) {
                return this.value.join('\n'); // Join array elements with line breaks
            }
            return this.value;
        }
    },
    data() {
        return {
            searchModel: '',
            replaceModel: '',
            copyTrigger: false,
            sendEhr: false,
            modality: 'telehealth',
            start: '',
            end: '',
            date: '',
            address: "",
            instructions: true,
            clinical_note: true,
            model: 'tab-2',
            title: {
                clinical_note: 'Clinical Note',
                instructions: 'Instructions',
            },
            isModalOpen: false,
            selectedTemplate: 'Templates',
            timeFormat: "hh:mm a",
            query: 'adipiscing',
            icon: "mdi-content-copy",
            copiedSection: null,
            snackbar: false,
            copyText: "",
            session: {
                summaryJson: {}, // Initialize as an empty object
                instructions: {}
            },
            isSendingEmail: false,
            searchModel: '',
            replaceModel: '',
            searching: false,
            searchingNotes: false,
            handleSaveDebounced: null,
            templateChanged: false

        };
    },
    methods: {
        updateSessionModality(modality) {
            this.modality = modality;
        },
        updateTime(start, end) {
            this.start = start.start.format('hh:mm A');
            this.end = start.end.format('hh:mm A');
            this.date = start.end.format('MM/DD/YYYY');
        },
        closeSearch() {
            this.searchingNotes = false;
        },
        searchAndReplace() {
            trackEvent(INSTRUCTIONS_SEARCH_AND_REPLACE, { userId: this.user.uid, userEmail: this.user.email, search: this.searchModel, replace: this.replaceModel, sessionId: this.sessionId });
            const regex = new RegExp(this.searchModel, 'g');
            this.session.instructions = this.session.instructions.replace(regex, this.replaceModel);
            this.handleSaveInstructions(true);

        },
        handleSaveOnBlur() {
            this.handleSaveInstructions()

        },
        triggerCopy() {
            if (this.disabledCopy) {
                this.openPriceDialog();
                return
            }
            this.copyTrigger = !this.copyTrigger; // Toggle to trigger the watcher in child
        },

        triggerSendEhr() {
            this.sendEhr = !this.sendEhr; // Toggle to trigger the watcher in child
        },

        openPriceDialog() {
            this.priceDialog = true;
            trackEvent(COPY_DISABLED, { userId: this.user.uid, userEmail: this.user.email, sessionId: this.sessionId, patientId: this.patientId });
        },

        copyContent() {
            this.$emit('copyContent');
        },

        updateSummary(event) {
            this.session.summary = event.target.value;
        },

        toggleUsePatientName() {
            this.userdata.forEach(user => {
                if (user.usePatientName) {
                    user.usePatientName = false
                    const { updateDocument } = useDocument('user', user.id)
                    updateDocument({
                        usePatientName: false
                    })
                }
                else {
                    user.usePatientName = true
                    const { updateDocument } = useDocument('user', user.id)
                    updateDocument({
                        usePatientName: true
                    })
                }
            })
        },

        updateAndPrintJson() {
            try {
                const parsedJson = JSON.parse(this.session.summaryJson);

                for (const key in this.computedValues) {
                    if (Object.hasOwnProperty.call(this.computedValues, key)) {
                        const textareaValue = this.computedValues[key];
                        parsedJson[key] = textareaValue;
                    }
                }
                return parsedJson;
            } catch (error) {
                console.error("Error parsing JSON:", error);
            }
        },


        summaryObject() {
            return JSON.parse(this.session.summaryJson)
        },

        getDescriptionWithLineBreaks(description) {
            if (!description) {
                return ''; // Return empty string if description is undefined
            }

            if (Array.isArray(description)) {
                var items = "";
                description.forEach(item => {
                    if (typeof item === 'string') {
                        const itemFull = item.split("/");
                        const desc = itemFull[0];
                        items = items + desc + "/";
                    }
                });
                const words = items.split('/');
                return words.filter(word => word.trim()).join('<br>');
            }

            if (typeof description === 'string') {
                const words = description.trim().split('/');
                return words.filter(word => word.trim()).join('<br>');
            }

            return ''; // Return empty string for any other case
        },

        lineBreakWords(template) {
            return template.trim().replace(/\s\s+/g, ' ').split(' ').join('<br>');
        },



        copyToClipboard(type) {

            this.icon = "mdi-check-bold";

            setTimeout(() => {
                this.icon = "mdi-content-copy";
            }, 1000);

            let resultString = '';
            if (type === this.title.clinical_note) {
                if (this.session.summaryJson) {
                    for (const key in this.computedValues) {
                        if (this.computedValues.hasOwnProperty(key)) {
                            const value = this.computedValues[key];
                            resultString += `${key}\n ${value}\n\n`;
                        }
                    }
                } else {
                    resultString = this.session.summary
                }
            } else {
                if (this.session.instructions) {
                    // add to resultString
                    resultString = this.session.instructions;
                }
            }

            navigator.clipboard.writeText(resultString)
                .then(() => {
                    // Successfully copied
                    this.snackbar = true
                    this.copyText = "Note copied successfully"
                })
                .catch((error) => {
                    console.error('Copy failed:', error);
                    this.snackbar = true
                    this.copyText = "Error coping the note"
                });
        },

        copySection(sectionName) {


            const sectionValue = this.computedValues[sectionName];
            var textToCopy = sectionName + "\n" + sectionValue


            navigator.clipboard.writeText(textToCopy)
                .then(() => {
                    // Successfully copied
                    this.snackbar = true
                    this.copyText = `${sectionName} copied successfully`
                })
                .catch((error) => {
                    console.error('Copy failed:', error);
                    this.copyText = `Error coping ${sectionName}`
                });
        },

        selectTemplate(template) {
            if (template.templateHeader == "+ Create Template") {
                this.isModalOpen = true;
            } else {
                this.selectedTemplate = template.templateHeader
                this.handleTemplateClick(this.selectedTemplate, template.id)
            }
            this.templateChanged = true;
        },

        openModal() {
            this.isModalOpen = true;
        },

        closeModal() {
            this.isModalOpen = false;
        },

        highlightText(word) {
            this.query = word;
            const highlightedWords = document.querySelectorAll('.highlighted');
            if (highlightedWords.length > 0) {
                highlightedWords[0].scrollIntoView();
            }
        },

        async getAudioUrl(path) {
            const res = await getAudio(path)
            return res
        },

    },
    async created() {
        this.handleSaveDebounced = debounce(this.updateStatusNote, 600); // a bit of delay due to note saving
    },
    setup(props) {

        const { user } = getUser()
        const { error, document: session } = getDocument('sessions', props.id)
        const { errorPatient, document: patient } = getDocument('patients', props.patientId)
        const { updateDocument } = useDocument('sessions', props.id)
        //const { documents: masterTemplates } = getCollectionGlobal('master-templates')
        const isPending = ref(false)
        const isPendingSave = ref(false)
        const notes = ref('')
        const selectedOption = ref('option0')
        const sessionId = props.id
        const language = ref('en')
        const instrutionsLanguage = ref('English')
        const usePatientName = ref('Use patient name')
        const { addDoc } = useCollection('user')
        const computedValues = ref({});
        const statusCheckbox = ref(false);
        const statusEnum = {
            ReadyForReview: 'Ready for Review',
            Reviewed: 'Reviewed'
        };
        const snackbarNotes = ref(false);
        const notesStatus = ref(statusEnum.ReadyForReview);
        const userClinicTemplates = ref([]);
        let items = ref([])
        const patientId = ref(null);
        patientId.value = props.patientId;

        const templateSettingsUserAction = ref(UserActions.NoteTemplateSettings)
        const summaryEditUserAction = ref(UserActions.SummaryEdit)

        const instructionsUpdated = ref(false);
        const disableInstructionsEmail = ref(true);

        const isEhrExtension = ref(false)
        const isRula = ref(false)
        isEhrExtension.value = ehrExtensionWhitelist()
        isRula.value = ehrExtensionWhitelistRula()

        //We use the get collection instead of the get document bcs we do not know the id of the document 
        const { documents: userdata } = getCollection(
            'user',
            ['userId', '==', user.value.uid]
        )

        const templates = ref([])

        const { documents: promptConfig } = getCollectionGlobal('prompt-config')

        const canUseNewClinicalNote = ref(false);
        canUseNewClinicalNote.value = newClinicalNoteWhiteListedAccounts()


        const fetchTemplates = async () => {
            try {
                const functions = getFunctions();
                const getTemplatesFunction = httpsCallable(functions, 'getTemplates');
                const response = await getTemplatesFunction({ userId: user.value.uid });
                if (response.data) {
                    const { templates: fetchedTemplates, lastTemplateId } = response.data;

                    // Transform templates into the format expected by the component
                    const transformedTemplates = fetchedTemplates.map(template => {
                        const templateData = {
                            id: template.id,
                            templateHeader: template.title || template.templateHeader,
                        };

                        // Handle the template content based on its structure
                        if (template.items) {
                            // Handle master template format
                            const templateSections = [];
                            for (const property in template.items) {
                                const item = template.items[property];
                                templateSections.push(`${property}/${item.dlevel}/${item.style}`);
                            }
                            templateData.template = templateSections;
                        } else if (template.template && typeof template.template === 'object' && !Array.isArray(template.template)) {
                            // Handle template object format (for summary templates)
                            const sections = [];
                            for (const sectionName in template.template) {
                                const section = template.template[sectionName];
                                if (section.content) {
                                    // Extract section name from content
                                    const contentMatch = section.content.match(/\[(.*?)\]/);
                                    if (contentMatch) {
                                        sections.push(`${sectionName}/Medium/Bullet points`);
                                    }
                                }
                            }
                            templateData.template = sections;
                        } else if (Array.isArray(template.template)) {
                            // Already in the correct format
                            templateData.template = template.template;
                        } else {
                            // Handle simple template format or create empty array
                            templateData.template = [];
                        }

                        return templateData;
                    });

                    // Sort templates alphabetically
                    transformedTemplates
                        .sort((a, b) =>
                            a.templateHeader.localeCompare(b.templateHeader)
                        );

                    // Add the "Create Template" option
                    transformedTemplates.push({
                        templateHeader: '+ Create Template',
                        template: 'Create your own template'
                    });

                    items.value = transformedTemplates;

                    // Set current template if there's a lastTemplateId
                    if (session.value?.templateId) {
                        const selectedTemplate = transformedTemplates.find(
                            template => template.id === session.value.templateId
                        );
                        if (selectedTemplate) {
                            currentTemplate.value = selectedTemplate.templateHeader;
                        }
                    }
                }
            } catch (error) {
                console.error('Error fetching templates:', error);
            }
        };


        // Call fetchTemplates when component is created

        watch(user, () => {
            if (user.value) {
                fetchTemplates();
            }
        });

        watch(session, async () => {
            // Load note status from session data if available
            if (session && session.value && session.value.notesStatus) {
                statusCheckbox.value = session.value.notesStatus === statusEnum.Reviewed;
                notesStatus.value = session.value.notesStatus;
            }

            if (session && session.value && session.value.instructions) {
                disableInstructionsEmail.value = false
            }
            if (session.value?.templateId) {
                const selectedTemplate = items.value.find(
                    template => template.id === session.value.templateId
                );
                if (selectedTemplate) {
                    currentTemplate.value = selectedTemplate.templateHeader;
                }
            }
        });

        const currentTemplate = ref('');
        watch(items, () => {
            if (session.value.templateId) {
                const selectedTemplate = items.value.find(template => template.id === session.value.templateId);
                if (selectedTemplate) {
                    currentTemplate.value = selectedTemplate.templateHeader;
                }
            }
        })

        function getPromptConfigObject() {
            const promptConfigObject = {};
            promptConfig.value.forEach(function (doc) {
                for (const key in doc) {
                    if (Object.hasOwnProperty.call(doc, key)) {
                        promptConfigObject[key] = doc[key];
                    }
                }
            });
            return promptConfigObject
        }


        const userActionDisabled = (userAction) => {
            return !!session.value && !getUserActionState(userAction, session.value.state)
        }

        const createPatientInstructions = async (refresh) => {
            disableInstructionsEmail.value = refresh;

            if (session.value.instructions && (refresh == false)) {
                return;
            }

            trackEvent(CREATE_PATIENT_INSTRUCTIONS, { userId: user.value.uid, userEmail: user.value.email, sessionId: sessionId, refresh: refresh })

            session.value.instructions = 'Generating instructions please wait...';

            const functions = getFunctions();
            const createInstructionTemplate = httpsCallable(functions, 'createInstructionTemplate');
            try {
                await createInstructionTemplate({ sessionId: sessionId, language: instrutionsLanguage.value });
            } catch (error) {
                console.log(`Error creating the Instructions ${error}`)
            }
        }

        const handleSaveInstructions = async (shouldSave) => {

            if (instructionsUpdated.value || shouldSave) {
                isPendingSave.value = true;
                instructionsUpdated.value = false;
                const { updateDocument } = useDocument('sessions', sessionId)

                if (session && session.value.instructions) {

                    const instructions = session.value.instructions;
                    await updateDocument({
                        instructions
                    })
                }

                setTimeout(() => {
                    isPendingSave.value = false;
                }, 1000);
            }
        }

        const handleTemplateClick = async (selectedTemplate, templateId) => {
            error.value = ""
            if (!session.value.transcript || session.value.transcript.length === 0) {
                error.value = "Transcript is empty!"
            } else {
                var promptConfigObject = getPromptConfigObject()
                var jsonTemplate = getSelectedTemplateJson(selectedTemplate, promptConfigObject)

                //Save last template if the user exist
                if (userdata.value.length > 0) {
                    userdata.value.forEach(user => {
                        const { updateDocument: updateUser } = useDocument('user', user.id)
                        const { updateDocument: updateSession } = useDocument('sessions', props.id)

                        if (typeof jsonTemplate === "string") {
                            jsonTemplate = JSON.parse(jsonTemplate);
                        }

                        let updateData = {
                            lastTemplate: jsonTemplate,
                            language: language.value,
                        }

                        if (!!templateId) {
                            updateData = { lastTemplateId: templateId, ...updateData }
                        }

                        var res = updateUser(updateData).then(response => {
                            console.log("Update successful");
                        }).catch(error => {
                            console.error("Error updating document:", error);
                        });

                        updateSession({
                            summary: 'Generating new clinical note, it can take up to 2min...'
                        })
                    });
                }
                console.log(`user id: ${user.value.uid}`)
                let payload = { template: jsonTemplate, templateId, transcript: session.value.transcript, sessionId: props.id, language: language.value, userId: user.value.uid, patientId: props.patientId }
                const functions = getFunctions();
                const generateSummary = httpsCallable(functions, 'generateSummary');
                try {
                    await generateSummary(payload)
                    // currentTemplate.value = selectedTemplate;
                    currentTemplate.value = selectedTemplate;
                } catch (error) {
                    console.log(`Error generating summary ${error} `)
                }
            }
        }

        //TODO method to improve
        function getSelectedTemplateJson(selectedValue, promptConfigObject) {
            // Object to store the result
            let result = {};

            //We loop through the selected items (list of templates) in the template list
            items.value.forEach(function (doc) {
                //If we find the selected template name in the list
                if (doc.templateHeader == selectedValue) {
                    if (doc.shareClinic) {
                        trackEvent(CLINICAL_TEMPLATE_SELECTED, { userId: user.value.uid, userEmail: user.value.email, clinicId: user.value.clinicId, templateId: doc.id, sessionId: sessionId });
                    }
                    if (typeof doc.language === 'undefined' || doc.language === null) {
                        language.value = 'English'
                    } else {
                        language.value = doc.language
                    }
                    //ToDo remove all this method, we do not need it anymore since we are sending the templateId
                    if (Array.isArray(doc.template)) {
                        var order = 0
                        // for (const index in doc.template) {
                        //     order++
                        //     const element = doc.template[index];
                        //     const itemFull = element.split("/")
                        //     const itemTitle = itemFull[0]
                        //     const dlevel = itemFull[1]
                        //     const style = itemFull[2]
                        //     let styledFormatted;
                        //     if (style.trim() == "Flow text") {
                        //         styledFormatted = " as a flowing text (not as bullet points) "
                        //     } else {
                        //         styledFormatted = " in bullet points "
                        //     }

                        //     // Construct the string and assign it to the result object
                        //     if (promptConfigObject[itemTitle]) {
                        //         result[itemTitle] = { content: `[Write this paragraph ${styledFormatted}.${dlevel} ${promptConfigObject[itemTitle]} ]`, order: order };
                        //     } else {
                        //         result[itemTitle] = { content: `[Write this paragraph ${styledFormatted}. ${dlevel}]`, order: order };
                        //     }
                        // }
                    } else { //TODO remove once we migrate users templates 
                        // Handle the case when doc.template is not an array
                        // (You might need to adjust this part based on how you want to handle this case)
                        result[doc.templateHeader] = doc.template;
                    }
                }
            });

            // Return the result object
            return result;
        }


        function formatArray(array) {
            if (array.every(item => typeof item === 'string')) {
                const bulletedText = array.map(item => `- ${item} `).join('\n');
                return bulletedText;
            } else if (array.every(item => typeof item === 'object')) {
                let result = '';
                for (const obj of array) {
                    result += `${formatObject(obj)} \n\n`;
                }
                return result.trim();
            } else {
                return JSON.stringify(array, null, 2); // Adjust the formatting as needed
            }
        }

        function formatObject(obj) {
            let result = '';

            for (const key in obj) {
                if (Array.isArray(obj[key])) {
                    result += `${key}: \n${formatArray(obj[key])} \n\n`;
                } else if (typeof obj[key] === 'object') {
                    result += `${key}: \n${formatObject(obj[key])} \n\n`;
                } else {
                    result += `${key}: ${obj[key]} \n`;
                }
            }

            return result.trim();
        }

        async function updateStatusNote($event) {
            const currentStatus = $event ? statusEnum.Reviewed : statusEnum.ReadyForReview;
            const { updateDocument } = useDocument('sessions', sessionId);
            await updateDocument({
                notesStatus: currentStatus
            });
            try {
                if (currentStatus == statusEnum.Reviewed) {
                    const functions = getFunctions();
                    const onCreateVector = httpsCallable(functions, 'on_create_new_vector');
                    var response = await onCreateVector({ patient_id: props.patientId, session_id: sessionId, user_id: user.value.uid })
                } else {
                    const functions = getFunctions();
                    const onDeleteVectors = httpsCallable(functions, 'on_delete_namespace_vectors');
                    var response = await onDeleteVectors({ patient_id: props.patientId, session_id: sessionId, user_id: user.value.uid })
                }
                //Save information function 
                console.log("Job done")

                trackEvent(NOTE_STATUS_UPDATE, { userId: user.value.uid, email: user.value.email, currentStatus, sessionId, source: "Web" })
            }
            catch (error) {
                trackEvent(NOTE_STATUS_UPDATE_FAILURE, { userId: user.value.uid, email: user.value.email, currentStatus, sessionId, error, source: "Web" })
                console.log('Something went wrong when updating the status: ', error)
            }

            snackbarNotes.value = true;
        }

        //disable copying of note
        const { isUserBlocked } = useBlockedUsers(user.value.uid);
        const userObject = ref({})
        // const { fetchSubscription, subscription } = useSubscription(user.value.id);
        let subscription = null

        const availableFeatures = JSON.parse(localStorage.getItem('availableFeatures'))

        subscription = availableFeatures?.includes('feature_sessions')


        onMounted(async () => {
            if (user.value) {
                userObject.value = await getUserCollection(user.value.uid);
            }
            try {
                isRula.value = await ehrExtensionWhitelistRula()
            } catch (error) {
                console.error('Error checking Rula whitelist:', error)
                isRula.value = false
            }
            fetchTemplates();
        })

        const disabledCopy = computed(() => {
            return isUserBlocked.value || (!subscription && userObject?.value?.counter > 50 && !userObject.value?.clinicId)
        })

        watch(patient, (newValue) => {
            console.log('Patient fields:', Object.keys(newValue || {}))
        })


        const navigation = computed(() => {
            let sessionDate = ''
            if (session.value?.date) {
                // Handle the Firestore Timestamp
                const date = session.value.date.toDate ? session.value.date.toDate() : new Date(session.value.date)
                sessionDate = date.toLocaleDateString()
            }

            return [
                {
                    title: 'Patients',
                    disabled: false,
                    href: '/patients/user',
                },
                {
                    title: getPatientFullName(patient.value) || 'Patient',
                    disabled: false,
                    href: `/patients/${props.patientId}`,
                },
                {
                    title: sessionDate || 'Session',
                    disabled: true,
                    href: `/patients/${patientId}/session/${sessionId}`,
                },
            ]
        })


        const priceDialog = ref(false)

        const isDialogOpen = ref(false)

        const openMenu = () => {
            isDialogOpen.value = true;
        }
        const closeUserSettingsDialog = async () => {
            isDialogOpen.value = false
            if (user.value) {
                userObject.value = await getUserCollection(user.value.uid);
            }
        }


        const editViewNote = ref(false)
        editViewNote.value = editViewNoteWhitelist()

        const editing = ref(true)

        if (editViewNote.value) {
            editing.value = false
        }

        const switchEditing = () => {
            editing.value = !editing.value
        }
        return { editViewNote, editing, switchEditing, isEhrExtension, isDialogOpen, openMenu, closeUserSettingsDialog, userObject, priceDialog, disabledCopy, canUseNewClinicalNote, summaryEditUserAction, templateSettingsUserAction, userActionDisabled, disableInstructionsEmail, instructionsUpdated, patientId, notesStatus, snackbarNotes, statusCheckbox, session, error, handleTemplateClick, notes, isPending, isPendingSave, selectedOption, templates, items, user, sessionId, computedValues, userdata, usePatientName, handleSaveInstructions, createPatientInstructions, instrutionsLanguage, updateStatusNote, currentTemplate, isRula, navigation, getPatientFullName }
    }
}


</script>

<style scoped>
.checkSession {
    position: absolute;
    left: 10px;
    align-items: center;
}

.session-details {
    display: grid;
    grid-template-columns: 0.5fr 1.5fr;
    gap: 80px;
    margin-left: 5%;
    margin-right: 5%;
}

.breadcrumb {
    background-color: transparent;
    margin-bottom: 0px;
    padding-bottom: 0px;
    margin-left: 5%;
    margin-right: 5%;
}


@media (max-width: 1172px) {
    .session-details {
        grid-template-columns: 1fr;
        /* This makes everything stack vertically */
        gap: 20px;
        /* Adjust gap for mobile, if needed */
    }

}

@media (max-width: 1455px) {
    .summary-copy {
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .checkSession {
        position: relative;
        left: 10px;
        align-items: center;
    }

}

.sessionSummary .v-btn-toggle {
    height: 34px;
}

.sessionSummary .v-btn-toggle .v-btn {
    background-color: inherit;
}

.summary-copy {
    padding: 8px;
    background-color: #fff;
    border-bottom: 1px solid rgba(224, 224, 224, 0.5);
}

.note-container {
    border-radius: 10px;
    border: 1px solid rgba(224, 224, 224, 0.5);
}

.container-tab {
    border-radius: 10px;
    border-bottom: none;
}

.text-center {
    background-color: white;
    box-shadow: 0 1px 4px #3232321A;
}

.toggle-label {
    color: #95979b;
}

.lowercase-text {
    text-transform: none !important;
    font-weight: bold;
    letter-spacing: normal;
    font-size: 16px;
}

.v-tooltip span {
    color: white !important;
}

.custom-autocomplete:focus {
    border-bottom: 2px solid #1976D2;
    /* Vuetify primary color */
}

.search-replace-wrapper {
    display: flex;
    justify-content: end;

}

.search-replace-card {
    display: flex;
    flex-direction: row;
    width: 60%;
    align-items: self-end;
    font-size: 0.9rem;
}


.tabs-container {
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
}

.patient-chart-options ::v-deep .v-tab--selected span {
    color: var(--bittersweet);
    font-size: medium;

}



@media (max-width: 1200px) {
    .search-replace-card {
        width: 100%;
    }
}

@media(max-width: 720px) {
    .summary-copy {
        gap: 10px;
        justify-items: center;
        align-items: center !important;
        display: flex;
        flex-wrap: wrap-reverse;
        /* margin: 0; */
        justify-content: center;
    }
}
</style>