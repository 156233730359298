import { trackEvent, RECORDER_USER_CHANGED_MICROHONE_MANUALLY, RECORDER_TRACK_OBJECT, RECORDER_MEDIA_RECORDER_OBJECT, RECORDER_NO_DATA_AVAILABLE, RECORDER_DEVICE_CHANGE, RECORDER_CONNECT_MICROPHONE_AUDIO_DEVICES, RECORDER_CONNECT_MICROPHONE_DEFAULT_AUDIO_DEVICE, RECORDER_CONNECT_MICROPHONE_AUDIO_STREAM, RECORDER_DEVICE_CHANGE_ERROR } from '@/utilities/analyticsService';
import { update } from '@/composables/useAudioDB';
export class BaseAudioRecorder {
    constructor(userId, patientId, sessionId) {
        this.mediaRecorder = null;
        this.audioChunks = [];
        this.audioBlob = null;
        this.audioUrl = null;
        this.isRecording = false;
        this.isPaused = false;
        this.stream = null;
        this.audioContext = null;
        this.destination = null
        this.deviceChangeHandler = this.onDeviceChange.bind(this);
        this.onDataAvailable = null
        this.micStream = null
        this.isDeviceChanging
        this.muteListener = null
        this.noStreamListener = null
        this.devicesListener = null

        this.userId = userId;
        this.patientId = patientId;
        this.sessionId = sessionId;
    }

    async getMediaStream() {
        throw new Error('getMediaStream must be implemented by subclasses');
    }

    async start(onDataAvailable, muteListener, noStreamListener, devicesListener) {
        if (this.isRecording) {
            throw new Error('Recording is already in progress');
        }

        try {
            this.muteListener = muteListener
            this.noStreamListener = noStreamListener
            this.devicesListener = devicesListener

            this.stream = await this.getMediaStream();
            this.mediaRecorder = new MediaRecorder(this.stream, {
                audioBitsPerSecond: 20000,
            });

            this.audioChunks = [];
            this.onDataAvailable = onDataAvailable;

            this.mediaRecorder.addEventListener('dataavailable', async (event) => {
                if (event.data.size > 0) {
                    this.audioChunks.push(event.data);
                    await update(this.sessionId, new Blob(this.audioChunks, { type: 'audio/webm' }))
                    const chunkBlob = new Blob([event.data], { type: 'audio/webm' });
                    this.onDataAvailable(chunkBlob)
                } else {
                    trackEvent(RECORDER_NO_DATA_AVAILABLE, { userId: this.userId, userEmail: this.userEmail, source: "Web", patientId: this.patientId, sessionId: this.sessionId, data: JSON.stringify(event) })
                }
            });

            this.mediaRecorder.addEventListener('stop', () => {
                this.createAudioBlob();
            });

            this.mediaRecorder.start(1000);

            trackEvent(RECORDER_MEDIA_RECORDER_OBJECT, { userId: this.userId, userEmail: this.userEmail, source: "Web", patientId: this.patientId, sessionId: this.sessionId, mediaRecorder: { audioBitratemode: this.mediaRecorder?.audioBitsPerSecond, audioBitsPerSecond: this.mediaRecorder?.audioBitsPerSecond, mimeType: this.mediaRecorder?.mimeType, state: this.mediaRecorder?.state } })

            this.isRecording = true;
            this.isPaused = false;
            return this.stream
        } catch (error) {
            console.error('Error starting recording:', error);
            throw error;
        }
    }

    stop() {
        if (!this.isRecording || !this.mediaRecorder) {
            throw new Error('No recording in progress');
        }

        this.micStop()

        this.mediaRecorder.stop();
        this.isRecording = false;
        this.isPaused = false;


        navigator.mediaDevices.removeEventListener('devicechange', this.deviceChangeHandler);

        if (this.audioContext) {
            this.audioContext.close();
            this.audioContext = null;
        }

    }

    micStop() {
        if (this.micStream) {
            this.micStream.getTracks().forEach(track => track.stop());
            this.micStream = null;
        }
    }

    pause() {
        if (!this.isRecording || this.isPaused) {
            throw new Error('Cannot pause: not recording or already paused');
        }

        this.micStop()
        this.mediaRecorder.pause();
        this.isPaused = true;
    }

    async resume() {
        if (!this.isRecording || !this.isPaused) {
            throw new Error('Cannot resume: not recording or not paused');
        }

        this.micStop()
        try {
            await this.connectMicrophone()
            this.mediaRecorder.resume();
            this.isPaused = false;

        } catch (e) {
            throw e
        }
    }

    createAudioBlob() {
        this.audioBlob = new Blob(this.audioChunks, { type: 'audio/webm' });
        this.audioUrl = URL.createObjectURL(this.audioBlob);
    }

    getAudioUrl() {
        return this.audioUrl;
    }

    getAudioBlob() {
        return this.audioBlob;
    }

    clear() {
        if (this.isRecording) {
            throw new Error('Cannot clear while recording is in progress');
        }

        if (this.audioUrl) {
            URL.revokeObjectURL(this.audioUrl);
        }

        this.audioChunks = [];
        this.audioBlob = null;
        this.audioUrl = null;
        this.stream = null;

        this.micStop()

        navigator.mediaDevices.removeEventListener('devicechange', this.deviceChangeHandler);

        if (this.audioContext) {
            this.audioContext.close();
            this.audioContext = null;
        }
    }

    async onDeviceChange(deviceId = null) {
        // This is needed because otherwise the micStream is getting initialized again and the tracks dont stop - resulting in the tab "Recording" icon
        if (this.isDeviceChanging) {
            console.log('already changing device');
            return;
        }

        this.isDeviceChanging = true;
        trackEvent(RECORDER_DEVICE_CHANGE, { userId: this.userId, userEmail: this.userEmail, source: "Web", patientId: this.patientId, sessionId: this.sessionId });

        try {
            this.micStop();

            if (this.isRecording) {
                this.muteListener(false)
                await this.connectMicrophone(true, deviceId);
            }
        } catch (error) {
            console.error('Error handling device change:', error);
            if (error.message === 'MicMuted') {
                this.muteListener(true)
            }
            trackEvent(RECORDER_DEVICE_CHANGE_ERROR, { userId: this.userId, userEmail: this.userEmail, source: "Web", patientId: this.patientId, sessionId: this.sessionId, error: { message: error.message, name: error.name, stack: error.stack } });

        } finally {
            this.isDeviceChanging = false;
        }
    }

    /**
    * @param {boolean} [throwsMuteError=true] - If true, throws an error if the mic is muted.
    */
    async connectMicrophone(throwsMuteError = true, deviceId = null) {

        const devices = await navigator.mediaDevices.enumerateDevices();
        const audioInputDevices = devices.filter(device => device.kind === 'audioinput');

        trackEvent(RECORDER_CONNECT_MICROPHONE_AUDIO_DEVICES, { userId: this.userId, userEmail: this.userEmail, source: "Web", patientId: this.patientId, sessionId: this.sessionId, audioInputDevices: JSON.stringify(audioInputDevices) });
        console.log('audioInputDevices:', audioInputDevices);


        let defaultDevice = audioInputDevices.find(device => device.deviceId === 'default');
        trackEvent(RECORDER_CONNECT_MICROPHONE_DEFAULT_AUDIO_DEVICE, { userId: this.userId, userEmail: this.userEmail, source: "Web", patientId: this.patientId, sessionId: this.sessionId, defaultAudioDevice: JSON.stringify(defaultDevice) });
        console.log('defaultDevice:', defaultDevice);

        if (deviceId) {
            defaultDevice = audioInputDevices.find(device => device.deviceId === deviceId);
            trackEvent(RECORDER_USER_CHANGED_MICROHONE_MANUALLY, { userId: this.userId, userEmail: this.userEmail, source: "Web", patientId: this.patientId, sessionId: this.sessionId, userChangedDevice: JSON.stringify(defaultDevice) });
        }

        if (defaultDevice) {
            this.micStream = await navigator.mediaDevices.getUserMedia({
                audio: {
                    deviceId: defaultDevice.deviceId,
                    echoCancellation: false,
                    noiseSuppression: false,
                }
            });
        } else {
            this.micStream = await navigator.mediaDevices.getUserMedia({
                audio: true
            });
        }

        this.devicesListener(audioInputDevices, defaultDevice)

        console.log('micStream:', this.micStream);

        console.log(`all audio tracks length is ${this.micStream.getAudioTracks().length}`, this.micStream.getAudioTracks());
        const audioTrack = this.micStream.getAudioTracks()[0];

        if (audioTrack?.muted) {
            this.muteListener(true, true)
            this.micStop()
            // throw error only when recording starts ore resumes, otherwise it's from device change event and we throw an error the mic never connects if it's muted and no mute/unmute listener will exist
            if (throwsMuteError) {
                throw new Error('MicMuted')
            }
        }

        audioTrack?.addEventListener('mute', () => {
            this.muteListener(true)
        })

        audioTrack?.addEventListener('unmute', () => {
            this.muteListener(false)
        })


        console.log('audio track', audioTrack);
        trackEvent(RECORDER_TRACK_OBJECT, { userId: this.userId, userEmail: this.userEmail, source: "Web", patientId: this.patientId, sessionId: this.sessionId, audioTrack: { contentHint: audioTrack?.contentHint, enabled: audioTrack?.enabled, id: audioTrack?.id, kind: audioTrack?.kind, label: audioTrack?.label, muted: audioTrack?.muted, readyState: audioTrack?.readyState } });


        trackEvent(RECORDER_CONNECT_MICROPHONE_AUDIO_STREAM, { userId: this.userId, userEmail: this.userEmail, source: "Web", patientId: this.patientId, sessionId: this.sessionId, audioStream: { active: this.micStream?.active, id: this.micStream?.id, } });
        console.log('micStream:', this.micStream);

        // to force error for testing
        // if (yes) {
        // this.micStream.getTracks().forEach((track) => track.stop());
        // }

        if (!this.micStream.active || !audioTrack.enabled) {
            this.noStreamListener()
            throw new Error("InactiveStreamError")
        }


        const newSource = this.audioContext.createMediaStreamSource(this.micStream);

        newSource.connect(this.destination);
    }
}
