<template>
  <div class="login-container">
    <div class="auth-header">
      <div></div> <!-- Empty div for flex spacing -->
      <p class="signup-link">
        Already have an account?
        <a href="#" @click.prevent="navigateToSignup">Sign up</a>
      </p>
    </div>
    <form @submit.prevent="handleSubmit">
      <h1 class="welcome-title">Welcome</h1>
      <h2 class="welcome-subtitle">mdhub clinical AI assistant</h2>

      <div v-show="!isZoom" class="auth-buttons">
        <v-btn class="auth-btn google-btn" @click="handleGoogleSignUp" :loading="isPendingGoogle" elevation="0" block>
          <v-icon left>
            <img src="@/assets/google_icon.svg" alt="Google Icon" />
          </v-icon>
          Continue with Google
        </v-btn>

        <v-btn class="auth-btn microsoft-btn" @click="handleMicrosoftSignUp" :loading="isPendingGoogle" elevation="0"
          block>
          <v-icon left>
            <img src="@/assets/ms-logo.svg" alt="Microsoft Icon" />
          </v-icon>
          Continue with Microsoft
        </v-btn>

        <div class="divider-container">
          <span class="divider-text">or</span>
        </div>
      </div>

      <div class="input-fields">
        <v-text-field type="email" placeholder="name@work-email.com" v-model="email" color="accent" variant="outlined"
          density="comfortable" class="auth-input" required />

        <v-text-field placeholder="Password" v-model="password" color="accent" variant="outlined" density="comfortable"
          class="auth-input" required :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" @click:append="show1 = !show1"
          :type="show1 ? 'text' : 'password'" />
      </div>

      <div v-if="error" class="error-message">{{ error }}</div>

      <div class="auth-links">
        <router-link :to="{ name: 'ForgotPassword' }" class="forgot-password">
          Forgot password?
        </router-link>
      </div>

      <v-btn class="submit-btn" elevation="0" :loading="isPending" type="submit" block>
        <span class="submit-btn-text">Login</span>
      </v-btn>

      <div class="terms-text">
        <p>
          By signing up, you agree to the
          <a href="https://mdhub.ai/terms-of-service" target="_blank">Terms of Service</a>
          and acknowledge the
          <a href="https://mdhub.ai/privacy-policy" target="_blank">Privacy Policy</a>
        </p>
      </div>
    </form>
  </div>
</template>

<script>
import useLogin from '@/composables/useLogin'
import { startAvailableFeaturesListener } from '@/composables/getAvailableFeatures'
import { getWhitelistedFeaturesForUser } from '@/composables/isWhitelistedAccount';
import useCollection from '@/composables/useCollection'
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import useSignupGoogle from '@/composables/useSignupGoogle'
import useSignupMicrosoft from '@/composables/useSignupMicrosoft'
import { checkUserExists } from '@/composables/userService';
import posthog from 'posthog-js'
import getUser from '@/composables/getUser'
import { getIsZoomApp } from '@/composables/configureZoom'
import { timestamp } from '@/firebase/config'
import { trackEvent, LOGIN_ERROR } from '@/utilities/analyticsService';

export default {
  mounted() {
    this.setMetaTags();
  },
  data() {
    return {
      show1: false,
      show2: true,
      password: 'Password',
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters',
        emailMatch: () => (`The email and password you entered don't match`),
      },
      siteKey: '0x4AAAAAAAdZZbmGeRAH8EVR',
    }
  },
  methods: {
    setMetaTags() {
      document.title = "mdhub Login - Access the AI Scribe for Behavioral Health Clinicians";
      let metaDescription = document.querySelector('meta[name="description"]');
      if (!metaDescription) {
        metaDescription = document.createElement('meta');
        metaDescription.setAttribute('name', 'description');
        document.getElementsByTagName('head')[0].appendChild(metaDescription);
      }
      metaDescription.setAttribute('content', 'Log in to mdhub, the #1 AI assistant for mental health care clinicians. Experience seamless, secure, and efficient AI assistant that you can fully focus on patient care');
    },
    navigateToSignup() {
      this.$router.push({ name: 'Signup' });
    },
  },

  setup() {
    const { err, addDoc } = useCollection('user')
    const { error, login, isPending } = useLogin()
    const { errorGoogle, signupGoogle, isPendingGoogle } = useSignupGoogle();
    const { errorMicrosoft, signupMicrosoft, isPendingMs } = useSignupMicrosoft();
    const router = useRouter()
    const email = ref('')
    const password = ref('')
    const { user } = getUser();
    const { isZoomApp } = getIsZoomApp();
    const isZoom = isZoomApp.value;

    const handleSubmit = async () => {
      try {
        const res = await login(email.value, password.value);
        if (!error.value) {
          createUserAndNavigate(res);
        }
      } catch (error) {
        trackEvent(LOGIN_ERROR, { userEmail: email.value, error: error })
      }
    }

    const handleGoogleSignUp = async () => {
      const res = await signupGoogle();
      if (!error.value) {
        createUserAndNavigate(res)
      }
    };

    const handleMicrosoftSignUp = async () => {
      const res = await signupMicrosoft();
      if (!errorMicrosoft) {
        createUserAndNavigate(res)
      }
    };

    function navigateToPatientList(name, email, userId) {
      posthog.identify(userId, { email: email, name: name });
      const redirectPath = router.currentRoute.value.query.redirect;
      document.title = 'Powering healthcare with AI.'
      if (redirectPath) {
        const route = router.resolve({ path: redirectPath });
        router.push({ name: route.name });
      } else {
        router.push({ name: 'UserPatients' });
      }
    }

    onMounted(() => {
      if (user.value !== null) {
        navigateToPatientList(user.value.displayName, user.value.email, user.value.uid);
      }
    });

    async function createUserAndNavigate(res) {
      const userExists = await checkUserExists(res.user.uid);
      if (!userExists) {
        await addDoc({
          email: res.user.email,
          name: res.user.displayName,
          userId: res.user.uid,
          speciality: "",
          promoCode: "",
          createdAt: timestamp()
        })
      }

      if (!error.value) {
        const asyncOperations = [startAvailableFeaturesListener(), getWhitelistedFeaturesForUser()];
        await Promise.all(asyncOperations);
        navigateToPatientList(res.user.displayName, res.user.email, res.user.uid)
      }
    }

    return {
      isZoom, email, password, handleSubmit, error, isPending, handleGoogleSignUp, isPendingGoogle, handleMicrosoftSignUp,
    }
  }
}
</script>

<style scoped>
.login-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1px;
}

.auth-header {
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1px;
  padding: 0 24px;
}

form {
  width: 100%;
  max-width: 480px;
  padding: 48px;
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.welcome-title {
  font-size: 48px;
  font-weight: 600;
  margin-bottom: 8px;
  text-align: left;
  color: #1a1f36;
}

.welcome-subtitle {
  font-size: 24px;
  color: #697386;
  margin-bottom: 32px;
  font-weight: 400;
  text-align: left;
}

.auth-buttons {
  margin-bottom: 32px;
}

.auth-btn {
  margin-bottom: 16px;
  height: 48px !important;
  border: 1px solid #e5e7eb !important;
  background-color: white !important;
  color: #1f2937 !important;
  text-transform: none !important;
  letter-spacing: normal !important;
  font-weight: 500 !important;
  border-radius: 8px !important;
  font-size: 14px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.auth-btn :deep(.v-icon) {
  margin-right: 8px !important;
  height: 20px !important;
  width: 20px !important;
}

.auth-btn img {
  height: 20px !important;
  width: 20px !important;
  object-fit: contain;
}

.divider-container {
  position: relative;
  text-align: center;
  margin: 24px 0;
}

.divider-container::before,
.divider-container::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 45%;
  height: 1px;
  background-color: #e5e7eb;
}

.divider-container::before {
  left: 0;
}

.divider-container::after {
  right: 0;
}

.divider-text {
  background-color: white;
  padding: 0 16px;
  color: #6b7280;
}

.auth-input {
  margin-bottom: 16px;
}

.error-message {
  color: #dc2626;
  margin-bottom: 16px;
  font-size: 14px;
}

.auth-links {
  text-align: right;
  margin-bottom: 16px;
}

.auth-links a {
  color: #6b7280;
  text-decoration: none;
}

.terms-text {
  font-size: 13px;
  color: #6b7280;
  margin-bottom: 0;
  line-height: 1.4;
  text-align: center;
}

.terms-text a {
  color: #6b7280;
  text-decoration: underline;
}

.submit-btn {
  margin-bottom: 16px !important;
  height: 48px !important;
  background-color: var(--bittersweet) !important;
  color: white !important;
  text-transform: none !important;
  letter-spacing: normal !important;
  font-weight: 500 !important;
  border-radius: 8px !important;
}

.submit-btn-text {
  color: white !important;
}

.signup-link {
  font-size: 14px;
  color: #6b7280;
}

.signup-link a {
  color: #4f46e5;
  text-decoration: none;
  font-weight: 500;
}

/* Dark theme overrides */
:deep(.v-theme--dark) {
  background-color: #121212;
}

:deep(.v-theme--dark) form {
  background-color: #1e1e1e;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.2);
}

:deep(.v-theme--dark) .welcome-title {
  color: #ffffff;
}

:deep(.v-theme--dark) .welcome-subtitle {
  color: rgba(255, 255, 255, 0.7);
}

:deep(.v-theme--dark) .auth-btn {
  background-color: rgba(255, 255, 255, 0.05) !important;
  border-color: rgba(255, 255, 255, 0.12) !important;
  color: white !important;
}

:deep(.v-theme--dark) .divider-text {
  background-color: #1e1e1e;
  color: rgba(255, 255, 255, 0.6);
}

:deep(.v-theme--dark) .divider-container::before,
:deep(.v-theme--dark) .divider-container::after {
  background-color: rgba(255, 255, 255, 0.12);
}

:deep(.v-theme--dark) .auth-links a,
:deep(.v-theme--dark) .terms-text,
:deep(.v-theme--dark) .terms-text a,
:deep(.v-theme--dark) .signup-link {
  color: rgba(255, 255, 255, 0.7);
}

:deep(.v-theme--dark) .signup-link a {
  color: #818cf8;
}
</style>