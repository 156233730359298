<template>
  <div class="d-flex flex-column justify-center settings-section rounded-border">
    <div>
      <v-card elevation="0">

        <v-card-title class="left-align mt-7 ml-6"> Notes Settings</v-card-title>
        <v-card-subtitle class="left-align mb-5 ml-6"> Those settings will apply to all new
          clinical notes.</v-card-subtitle>
        <div class="left-align mb-5 mt-7 ml-10">
          <p>I want to use: </p>
          <v-btn-toggle class="toggle-box d-flex justify-left mb-2 mb-0" v-model="usePatientName" rounded="l"
            color="grey-lighten-5" group mandatory @update:model-value="saveSettings">
            <v-btn :value="true">
              Patient Name
            </v-btn>
            <v-btn :value="false">
              Do not use Name
            </v-btn>
          </v-btn-toggle>
          <p>I want to use: </p>
          <v-btn-toggle class="toggle-box d-flex justify-left mb-2 mb-0" v-model="subject" rounded="l"
            color="grey-lighten-5" group mandatory @update:model-value="saveSettings">
            <v-btn :value="'patient'">
              Patient
            </v-btn>

            <v-btn :value="'client'">
              Client
            </v-btn>

          </v-btn-toggle>
          <p>Use ICD-10 codes in note: </p>
          <v-btn-toggle class="toggle-box d-flex justify-left mb-2 mb-0" v-model="icd10codes" rounded="l"
            color="grey-lighten-5" group mandatory @update:model-value="saveSettings">
            <v-btn :value="true">
              Yes
            </v-btn>

            <v-btn :value="false">
              No
            </v-btn>

          </v-btn-toggle>
          <p v-if="canSeePreviousNote">Include previous clinical note:</p>
          <v-btn-toggle v-if="canSeePreviousNote" class="toggle-box d-flex justify-left mb-2 mb-0"
            v-model="usePreviousNote" rounded="l" color="grey-ligthen-5" group mandatory
            @update:model-value="saveSettings">
            <v-btn :value="true">
              Yes
            </v-btn>
            <v-btn :value="false">
              No
            </v-btn>
          </v-btn-toggle>
          <p>Include Session Details as note header:</p>
          <v-btn-toggle class="toggle-box d-flex justify-left mb-2 mb-0" v-model="includeSessionDetails" rounded="l"
            color="grey-ligthen-5" group mandatory @update:model-value="saveSettings">
            <v-btn :value="true">Yes</v-btn>
            <v-btn :value="false">No</v-btn>
          </v-btn-toggle>
          <p>Include CPT Codes in note:</p>
          <v-btn-toggle class="toggle-box d-flex justify-left mb-0" v-model="includeCPT" rounded="l"
            color="grey-lighten-5" group mandatory @update:model-value="saveSettings">
            <v-btn :value="true">Yes</v-btn>
            <v-btn :value="false">No</v-btn>
          </v-btn-toggle>
        </div>
        <v-divider></v-divider>
        <v-card-actions v-if="dialog" class="d-flex justify-end">
          <v-btn variant="text" @click="closeDialog">
            Save & Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </div>
  <v-snackbar v-model="snackbar" :timeout="1000" bottom right color="gray" elevation="24" class="text-center">
    {{ snackbarText }}
    <template v-slot:actions>
      <v-btn icon @click="snackbar = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script setup>

import { ref, defineProps, defineEmits } from 'vue';
import useDocument from '@/composables/useDocument'
import { previousNoteWhitelist } from '@/composables/isWhitelistedAccount'

const props = defineProps({
  userSettings: Object,
  userId: String,
  dialog: Boolean
})

const emit = defineEmits(['close-dialog'])

const closeDialog = () => {
  emit('close-dialog')
}

const canSeePreviousNote = ref(false)
canSeePreviousNote.value = previousNoteWhitelist()

const { updateDocument } = useDocument('user', props.userId)

const usePatientName = ref(null)
const icd10codes = ref(true)
const usePreviousNote = ref(false)
const subject = ref(null)
const narration = ref('third')
const includeSessionDetails = ref(false)
const includeCPT = ref(false)

const pendingSave = ref(false);
const snackbarText = ref('Settings are saved');
const snackbar = ref(false);


usePatientName.value = props.userSettings?.usePatientName ?? false;
subject.value = props.userSettings?.subject ?? null;
icd10codes.value = props.userSettings?.icd10codes ?? false;
narration.value = props.userSettings?.narration ?? false;
usePreviousNote.value = props.userSettings?.usePreviousNote ?? false;
includeSessionDetails.value = props.userSettings?.includeSessionDetails ?? false;
includeCPT.value = props.userSettings?.includeCPT ?? false;

const saveSettings = async () => {
  pendingSave.value = true;
  try {
    await updateDocument({
      settings: { 
        usePatientName: usePatientName.value, 
        subject: !!subject.value ? subject.value : 'patient', 
        narration: narration.value, 
        icd10codes: icd10codes.value, 
        usePreviousNote: usePreviousNote.value, 
        includeSessionDetails: includeSessionDetails.value,
        includeCPT: includeCPT.value
      },
    })
  } catch (error) {

    console.log('error in saving settings', error)

  }

  pendingSave.value = false
  snackbar.value = true;
}


</script>

<style scoped>
.left-align {
  text-align: left;
}

.settings-section {
  height: 100%;
  width: 100%;
  padding: 3px;
  background-color: white;
}
</style>