<template>
  <div>
    <div v-if="patient">
      <v-card class="pa-2 mt-4 patient-info-section" title="Diagnosis" elevation="0">
        <v-row class="ml-4 mr-4">
          <!-- Column for Patient Details including ICD-10 -->
          <!-- ICD-10 Codes -->
          <!-- To be delted if everything works fine with the new autocomplete. 
          <v-col cols="12">
            <v-text class="text-h8">ICD-10 Codes</v-text>
            <v-autocomplete variant="underlined" flat no-resize v-model="patient.icd10" closable-chips
              @update:modelValue="handleSaveDebounced" multiple chips clearable :items="icd10Codes" persistent-hint />
          </v-col> -->
          <v-col cols="12">
            <v-text class="text-h8">ICD-10 Codes</v-text>
            <ICD10Autocomplete v-model="patient.icd10" @update:modelValue="handleSaveDebounced" />
          </v-col>
          <!-- Column for Patient Notes -->
          <v-col cols="12">
            <v-text class="mb-2 text-h8">Patient Notes</v-text>
            <v-textarea flat placeholder="Notes" rows="1" row-height="15" auto-grow v-model="patient.diagnosis"
              @update:modelValue="handleSaveDebounced" bg-color="#ffffff" />
          </v-col>
        </v-row>
        <v-progress-circular v-if="isPendingSave" indeterminate color="primary"></v-progress-circular>
      </v-card>
    </div>
  </div>

  <v-dialog v-model="showDialog" max-width="500">

    <v-card class="pa-5">
      <v-card-title class="mb-4">
        <span class="headline">Which additional information do you want to see?</span>
      </v-card-title>
      <v-card-text>
        <v-select v-model="selectedOption" :items="dropdownOptions" label="Information" variant="underlined"></v-select>
        <v-text-field v-model="newSectionTitle" variant="underlined" label="Information"
          v-if="selectedOption === 'Other'"></v-text-field>
        <v-select v-model="sourceOption" :items="sourceMethods" label="Source" variant="underlined"></v-select>



      </v-card-text>
      <div v-if="errorDialog != ''" class="error d-flex flex-column align-center justify-center">
        {{ errorDialog }}
      </div>
      <v-card-actions class="d-flex align-end justify-end">
        <v-btn color="primary" @click="addPatientInfo">Add</v-btn>
        <v-btn color="primary" @click="showDialog = false">Cancel</v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>
</template>

<script>

import getDocumentOnce from '@/composables/getDocumentOnce'
import { computed } from '@vue/reactivity'
import moment from 'moment';
import { ref, watch } from 'vue'
import { getFunctions, httpsCallable } from "firebase/functions";
import getComplexCollection from '@/composables/getComplexCollection'
import useComplexCollection from '@/composables/useComplexCollection'
import axios from "axios"
import useDocument from '@/composables/useDocument'
import { firebaseConfig } from '@/firebase/config'
import icd10CodesData from '@/assets/icd10cm-codes-April-2024.json';
import ICD10Autocomplete from '@/components/ICD10Autocomplete.vue';
import { debounce } from 'lodash';
import getUser from '@/composables/getUser'
import { Timestamp } from "@firebase/firestore";
import getCollection from '@/composables/getCollection'
import { SessionState } from '@/composables/getUserActionState';
import { useRoute } from 'vue-router'
import { getIsZoomApp } from '@/composables/configureZoom';

export default {
  props: ['patientId'],
  components: {
    ICD10Autocomplete
  },
  data() {
    return {
      icd10Codes: icd10CodesData.map(item => `[${item.code}] ${item.description}`),
      handleSaveDebounced: null,
      audioFile: null,
      transcriptionResult: '',
      selectedFile: null,
      otherOptionText: '', // Stores the text entered for the "Other" option
      newSectionTitle: '', // Your existing data properties
      dropdownOptions: ['Medication', 'Allergies', 'Familiy history', 'Medical history', 'Other'],
      sourceMethods: ['PDF', 'Previous sessions'],
      headers: [
        {
          title: 'Date', value: 'date', align: 'center',
        },
        {
          title: 'Medication', value: 'medication', align: 'center',
        },
        {
          title: 'Dose', value: 'dose', align: 'center',
        },
        {
          title: 'Reason', value: 'reason', align: 'center',
        },
        {
          title: 'Actions', value: 'actions', align: 'center',
          sortable: false
        },

      ],
    };
  },
  created() {
    this.handleSaveDebounced = debounce(this.handleSave, 1000); // 1 second delay        
  },
  computed: {
    formattedDOB: {
      get() {
        if (!this.patient || !this.patient.dob)
          return "";
        if (typeof this.patient.dob === "string") {
          return this.patient.dob;
        }
        else {
          const date = this.patient.dob.toDate();
          return moment(date).format('YYYY-MM-DD');
        }
      },
      set(newValue) {
        this.patient.dob = newValue; // This assumes that the backend accepts and can store this format. Modify as needed.				
        this.handleSave();
      }
    },
  },
  methods: {
    getFormattedDate(date) {
      //return new Date(date.seconds * 1000)
      const formattedDate = new Date(date)
        .toLocaleDateString("en-US", {
          day: "2-digit",
          month: "short",
          year: "numeric"
        });
      return formattedDate
    },
    handleChange(event) {
      // Update the audioFile variable when the file input changes
      this.audioFile = event.target.files[0];
    },
    getDOB(date) {
      if (date) {
        return moment(String(date)).format('DD- MM -YYYY');
      }
      else {
        return "";
      }
    },
    getLabel(index) {
      return index < this.items.length ? this.items[index].keyword : `Name ${index + 1}`;
    },
    generate(index) {
      this.isPullingData = true;
      let pullDataObject = { patientId: this.patientId, keyword: this.items[index].keyword, historicId: this.items[index].id };
      const functions = getFunctions();
      const pullData = httpsCallable(functions, 'pullData');
      pullData(pullDataObject)
        .then((result) => {
          this.isPullingData = false;
        }).catch((error) => {
          console.log(`Error pulling data ${error}`);
          this.isPullingData = false;
        });
    },
    openFileInput(index) {
      this.currentIndex = index;
      this.$refs.fileInput[0].click();
    },
    handleFileUpload(event, index) {
      console.log("==== HandFileUpload triggered ====");
      // TODO - Do we need to have better client side restrictions here to only allow one file being sent
      this.selectedFile = event.target.files[0];
      if (this.selectedFile) {
        this.extractPdfData(this.currentIndex);
      }
    },
    async extractPdfData(index) {
      console.log("==== extractPdfData Fired ====");
      let extractPdfData = { patientId: this.patientId, keyword: this.items[index].keyword, historicId: this.items[index].id };
      try {
        if (!this.selectedFile) {
          // TODO - add better error handling e.g. check if pdf file
          console.error('No file selected');
          return;
        }
        const formData = new FormData();
        formData.append('pdfTitle', extractPdfData.keyword);
        formData.append('pdfFile', this.selectedFile);
        formData.append('patientId', extractPdfData.patientId);
        formData.append('historicId', extractPdfData.historicId);
        await axios.post(firebaseConfig.firebaseFunctionsBaseUrl + "/extractPdfSummaryByKeyword", formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      }
      catch (error) {
        console.error('Error:', error);
      }
    }
  },
  setup(props) {
    const gender = ref('');
    const { updateDocument } = useDocument('patients', props.patientId);
    const { error2, documents: hist2 } = getComplexCollection('patients', props.patientId, 'historic');
    const { err, addDoc, updateDoc } = useComplexCollection('patients', 'historic');
    const ownership = computed(() => {
      return patient.value && user.value && user.value.uid == patient.value.userId;
    });
    const patientId = props.patientId;
    const newSectionTitle = ref('');
    const showDialog = ref(false);
    const isPendingSave = ref(false);
    const selectedOption = ref('');
    const sourceOption = ref('');
    const errorDialog = ref('');
    const isPullingData = ref(false);
    const { user } = getUser();
    const icd10 = ref('')
    const patientAge = ref('');
    const route = useRoute()
    const patient = ref(null);
    const error = ref(null);
    const { isZoomApp } = getIsZoomApp();
    const icd10Hint = ref(''); // Default value for the hint

    const { documents: patientSessions } = getCollection(
      'sessions',
      ['patientId', '==', props.patientId]
    )

    const { documents: userData } = getCollection(
      'user',
      ['userId', '==', user.value.uid])

    watch(userData, (newVal) => {
      if (newVal && newVal.length > 0) {
        const icd10codes = newVal[0].settings?.icd10codes;
        if (icd10codes !== undefined) {
          if (icd10codes === "true") {
            icd10Hint.value = 'ICD-10 codes will be included in the clinical notes';
          } else {
            icd10Hint.value = 'ICD-10 codes will not be included in the clinical notes';
          }
        } else {
          icd10Hint.value = 'ICD-10 codes will be included in the clinical notes';
        }
      } else {
        icd10Hint.value = 'ICD-10 codes will be included in the clinical notes';
      }
    }, { immediate: true });

    let patientHistoric = ref([
      { keyword: 'Diagnosis', summary: 'Value', id: 'xyc', source: 'pdf' },
    ]);
    let button = { keyword: 'Button', summary: '', id: '', source: 'sessions' };
    let items = ref([]);


    watch(hist2, (newHistoric) => {
      items.value = [];
      patientHistoric.value.forEach(function (doc) {
        items.value.push(doc);
      });
      if (newHistoric) {
        newHistoric.forEach(function (doc) {
          items.value.push(doc);
        });
      }
    });
    const addPatientInfo = async () => {
      var keyword = "";
      if (newSectionTitle.value == "" && selectedOption.value == 'Other') {
        errorDialog.value = "Please add the information";
      }
      else if (selectedOption.value == '') {
        errorDialog.value = "Please select an option";
      }
      //show error
      else if (sourceOption.value == '') {
        errorDialog.value = "Please select the source";
      }
      else {
        if (selectedOption.value == 'Other' && newSectionTitle.value != "") {
          keyword = newSectionTitle.value;
        }
        else {
          keyword = selectedOption.value;
        }
        await addDoc(patientId, {
          keyword: keyword,
          summary: "",
          source: sourceOption.value
        });
        errorDialog.value = "";
        newSectionTitle.value = "";
        selectedOption.value = "";
        sourceOption.value = "";
        showDialog.value = false;
      }
    };
    const handleSave = async () => {
      isPendingSave.value = true;

      const updatedPatientsAge = handlePatientAge();
      const existingAge = returnPatientAge();
      let ageObject = {};
      // update age/dob only if changed
      if (existingAge !== patientAge.value) {
        const dob = patient.value.dob !== updatedPatientsAge ? updatedPatientsAge : patient.value.dob;
        const age = patientAge.value !== existingAge ? +patientAge.value : null;
        ageObject = {
          dob,
          age
        };
      }


      const res = await updateDocument({
        diagnosis: (typeof patient.value.diagnosis !== 'undefined') ? patient.value.diagnosis : "",
        patientName: patient.value.patientName,
        ...ageObject,
        gender: patient.value.gender,
        icd10: patient.value.icd10 || null
      });

      isPendingSave.value = false;
    };


    const handlePatientAge = () => {
      const currentYear = new Date().getFullYear();
      const yearOfBirth = currentYear - patientAge.value;
      const currentDay = new Date().getDate();
      const currentMonth = new Date().getMonth();
      const dob = new Date(yearOfBirth, currentMonth, currentDay);
      const dobTimestamp = Timestamp.fromDate(dob);
      return dobTimestamp
    }

    const returnPatientAge = () => {
      if (!patient.value || !patient.value.dob) return "";
      // dob is an object so we need to handle it as such
      const dobSeconds = patient.value.dob.seconds;
      const dobMilliseconds = dobSeconds * 1000 + patient.value.dob.nanoseconds / 1e6; // Convert nanoseconds to milliseconds -> this equals to 1000000
      const dobDate = new Date(dobMilliseconds);
      const age = moment().diff(moment(dobDate), 'years');

      return age.toString();
    };

    const getPatient = async () => {
      const { errorDoc, document: patientDoc } = await getDocumentOnce('patients', props.patientId)
      patient.value = patientDoc?.value || null;
      error.value = errorDoc?.value || null;
      patientAge.value = returnPatientAge();
    }

    watch(() => route.value?.name === "PatientOverview", getPatient, { immediate: true })

    return { isZoomApp, patientSessions, error, patient, ownership, gender, patientId, items, hist2, addPatientInfo, newSectionTitle, showDialog, handleSave, isPendingSave, selectedOption, sourceOption, errorDialog, isPullingData, isPendingSave, patientAge, icd10Hint };
  },
}
</script>

<style scoped>
.occupy-all-space {
  grid-column: 1 / -1;
  /* Span from column 1 to the last column */
}

.patient-info-container {
  grid-column: 2;
  /* Place patient-info-container in the second column */
  display: flex;
  flex-wrap: wrap;

}

.add-button {
  grid-column: 3;
  /* Place add-button in the third column */
}

.patient-info-section {
  font-size: 1rem;
  text-align: left;
  position: relative;
  background-color: white;
  padding: 4px;
  margin-top: 3.5%;
  font-family: inherit;
  font-size: inherit;
  line-height: 1.5;
  border-radius: 10px;
  border: 1px solid rgba(224, 224, 224, 0.5);
}

.diagnosis-textarea {

  border-radius: 14px;
  padding: 1%;
}


.patient-details-section {
  font-size: 1rem;
  text-align: left;
  position: relative;
  background-color: white;
  border-radius: 14px;
  padding: 4px;
  margin-top: 3.5%;
  font-family: inherit;
  font-size: inherit;
  line-height: 1.5;
  height: 210px;
  width: 100%;
}

.patient-details-section-button {
  font-size: 1rem;
  text-align: left;
  position: relative;
  background-color: white;
  border-radius: 14px;
  font-family: inherit;
  font-size: inherit;
  line-height: 1.5;
  height: 210px !important;
  width: 100%;
}

.patient-details-section-button:hover {
  font-size: 1rem;
  text-align: left;
  position: relative;
  background-color: #f3f3f3;
  border-radius: 14px;
  font-family: inherit;
  font-size: inherit;
  line-height: 1.5;
  height: 210px !important;
  width: 100%;
}

.patient-avatar {
  max-width: 200px;
  border-radius: 10%;
}

.beta-text {
  color: var(--bittersweet);
}
</style>
