<template>
    <div class="drawer-body">
        <div class="d-flex flex-column text-left mx-2 px-8">
            <div class="d-flex align-center mt-2">
                <span class="muted text-caption" v-if="muted && recording">Your microphone is muted. Please unmute or
                    change your microphone resume.</span>
                <p v-if="recording" class="ml-auto mb-0 text-caption">{{ formattedTime }}</p>
            </div>

            <div v-if="browser?.includes('Chrome')">
                <p class="error mb-0" v-if="recording && audioDevices.length === 0">No audio sources</p>
                <v-select v-if="recording" v-model="defaultDevice" @update:modelValue="micSelected" return-object
                    variant="plain" label="Audio source" :items="audioDevices" item-value="deviceId"
                    item-title="label"></v-select>
            </div>

            <div class="d-flex align-center justify-center" v-if="recording">
                <AVMedia v-if="!paused" class="awc full-width" :media="stream" type="frequ" frequ-direction="mo"
                    :frequ-lnum="60" :line-width="2" line-color="#808080" />
                <p v-else class="awc mb-0 d-flex justify-center align-center">Audio Paused</p>
            </div>
            <h5 class="mt-6">Template</h5>
            <v-expansion-panels class="followup-section" v-model="templatePanel">
                <v-expansion-panel elevation="0" :value="true">
                    <v-expansion-panel-title class="recorder-title py-4 text-start">
                        {{ selectedTemplate?.title ? `${selectedTemplate.title}` : `Loading...` }}
                    </v-expansion-panel-title>
                    <v-expansion-panel-text>
                        <v-list>
                            <v-tooltip v-for="(template, i) in userTemplates" :key="i" location="start">
                                <template v-slot:activator="{ props: tooltipProps }">
                                    <v-list-item v-bind="tooltipProps" @click="selectTemplate(template)"
                                        class="text-start">
                                        <v-list-item-title class="d-flex align-center">
                                            <v-icon left class="mr-4">mdi-file-document-outline</v-icon>
                                            {{ template?.title }}
                                        </v-list-item-title>
                                    </v-list-item>
                                </template>
                                <span>
                                    <div v-for="(section, index) in template.template" :key="index"
                                        class="tooltip-text">
                                        {{ section }}
                                    </div>
                                </span>
                            </v-tooltip>
                            <v-list-item v-if="userTemplates.length === 0">
                                <v-list-item-title class="d-flex align-center">
                                    Loading templates...
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-expansion-panel-text>
                </v-expansion-panel>
            </v-expansion-panels>
            <h5 class="mt-6">Questionnaire</h5>
            <v-expansion-panels class="followup-section" v-model="questionnairePanel">
                <v-expansion-panel elevation="0" :value="true">
                    <v-expansion-panel-title class="recorder-title py-5 text-start">
                        {{ selectedQuestionnaire ? selectedQuestionnaire.title : (loading ? 'Loading...' : `Select a
                        questionnaire to be used as input for the note.`)
                        }}
                    </v-expansion-panel-title>
                    <v-expansion-panel-text>
                        <v-list>
                            <v-list-item v-for="(questionnaire, i) in questionnaires" :key="i"
                                @click="selectQuestionnaire(questionnaire)">
                                <v-list-item-title class="d-flex align-center">
                                    <v-icon left class="mr-4">mdi-text-box-edit-outline</v-icon>
                                    {{ questionnaire?.title }} &nbsp;|&nbsp;
                                    {{ questionnaire?.submittedAt ? formatDate(questionnaire.submittedAt) : '-' }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="questionnaires.length === 0">
                                <div v-if="!loading" class="text-left">
                                    <p>No questionnaires sent to {{ getPatientFullName(patient) }}</p>
                                </div>
                                <v-list-item-title v-else>
                                    Loading...
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="!loading && questionnaires.length > 0 && !!selectedQuestionnaire"
                                @click="selectedQuestionnaire = null">
                                <v-list-item-title class="d-flex align-center">
                                    <v-icon left class="mr-4">mdi-alpha-x-circle-outline</v-icon>
                                    Unselect questionnaire
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-expansion-panel-text>
                </v-expansion-panel>
            </v-expansion-panels>



            <h5 class="mt-6" v-if="renderedMarkdown">Follow up</h5>
            <v-expansion-panels v-if="renderedMarkdown" class="followup-section" expand v-model="followUpPanel">
                <v-expansion-panel elevation="0">
                    <v-expansion-panel-title class="recorder-title pt-4 pb-4">
                        {{ followupDate }}
                    </v-expansion-panel-title>
                    <v-expansion-panel-text>
                        <div class="text-left" v-html="renderedMarkdown"></div>
                    </v-expansion-panel-text>
                </v-expansion-panel>
            </v-expansion-panels>

            <h5 class="mt-6">Additional Notes</h5>
            <v-textarea v-model="additionalNotes" @input="handleNotesChange" auto-grow
                placeholder="e.g. mood subdued, avoided eye contact, quick to smile." rows="10"
                class="followup-section  mb-16" variant="solo" flat></v-textarea>

        </div>

        <div class="actions d-flex pa-4 flex-column flex-md-row" v-if="recording">
            <v-btn height="50" variant="text" @click="discardDialog = true">
                <v-icon size="x-large" class="mr-4">mdi-delete</v-icon>
                <span style="text-transform: none">Discard Recording</span>
            </v-btn>
            <RecorderActions class="ml-auto" :additionalNotes="additionalNotes" :selectedTemplate="selectedTemplate"
                :selectedQuestionnaire="selectedQuestionnaire" :followup="followup" />
        </div>

        <v-dialog v-model="discardDialog" width="400px">
            <v-card class="pa-4 d-flex flex-column">
                <h4>Are you sure?</h4>
                <p>This action will delete the current audio recording and will not create a Clinical Note.</p>
                <div class="d-flex align-center ml-auto">
                    <v-btn height="50" variant="text" @click="discardDialog = false">
                        <span style="text-transform: none;">Cancel</span>
                    </v-btn>
                    <v-btn height="50" variant="text" class="ml-2" @click="discard">
                        <v-icon size="x-large" class="mr-4">mdi-delete</v-icon>
                        <span style="text-transform: none">Discard</span>
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script setup>
import { useRecorderStore } from '@/store/recorder'
import { storeToRefs } from 'pinia'
import { ref, computed, onMounted, watch } from 'vue';
import { getFunctions, httpsCallable } from "firebase/functions";
import getUser from '@/composables/getUser'
import { Timestamp } from "@firebase/firestore";
import { marked } from 'marked';
import { AVMedia } from 'vue-audio-visual'
import moment from 'moment'
import { trackEvent, RECORDER_TEMPLATE_SELECTED, RECORDER_QUESTIONNAIRE_SELECTED, RECORDER_DISCARD_SESSION, RECORDER_DISCARD_SESSION_ERROR } from '@/utilities/analyticsService';
import RecorderActions from '@/components/recorder/Actions.vue';
import { defineProps, defineEmits } from 'vue';
import { getPatientFullName } from '@/utilities/patientUtils';

const props = defineProps({
    selectedTemplate: Object,
    patientQuestionnaireId: Object,
    additionalNotes: String,
    followupUsed: String
})

const emit = defineEmits(['update:selectedTemplate', 'update:additionalNotes', 'update:patientQuestionnaireId', 'update:followupUsed']);

const { user } = getUser();
const functions = getFunctions();

const store = useRecorderStore();
const { paused, recording, patientId, patient, stream, sessionId, drawer, elapsedTime, muted, audioDevices, defaultDevice } = storeToRefs(store)

const { stopRecording, setOverlay, toggleDrawer, clear, selectMicrophone } = store

const browser = ref(null);
onMounted(async () => {
    // getAudioDevices();
    browser.value = navigator.userAgent
    await Promise.all([
        getTemplates(),
    ])
})

// const microphones = ref([]);
// const defaultMicrophoneId = ref(null);

// const getAudioDevices = async () => {
//     const devices = await navigator.mediaDevices.enumerateDevices();
//     microphones.value = devices.filter(device => device.kind === 'audioinput');

//     defaultMicrophoneId.value = microphones.value.find(device => device.deviceId === 'default') || microphones.value[0]
// }

// watch(defaultDevice, async (oldVal,  newVal) => {
//     if(!oldVal) return
//     if (newVal) {
//         console.log('New Microphone Selected', newVal)
//         await selectMicrophone(newVal.deviceId)
//     }
// })

const micSelected = async () => {
    await selectMicrophone(defaultDevice.value.deviceId)
}

const loading = ref(false);
watch(drawer, async (val) => {
    if (recording.value) return
    if (val) {
        loading.value = true;
        await Promise.all([
            getPatientQuestionnaires(),
            getFollowup(),
            getTemplates()
        ])
        loading.value = false;
    }
    if (!val) {
        questionnaires.value = [];
        selectedQuestionnaire.value = null;
        questionnairePanel.value = false
        userTemplates.value = []

        followup.value = '';
        followupDate.value = '';
        followUpPanel.value = false;
        additionalNotes.value = ''
    }
})

const userTemplates = ref([]);
const selectedTemplate = ref(null);
const templatePanel = ref(true);

const getTemplates = async () => {
    userTemplates.value = []; // Clear existing templates to prevent duplication
    const getTemplatesFunction = httpsCallable(functions, 'getTemplates');
    const result = await getTemplatesFunction({ userId: user.value.uid });
    userTemplates.value = result.data.templates
        .filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i) // Remove duplicates
        .sort((a, b) => (a.title?.toLowerCase() || '').localeCompare(b.title?.toLowerCase() || ''));

    if (result.data.lastTemplateId) {
        selectedTemplate.value = userTemplates.value.find(template => template.id === result.data.lastTemplateId);
        templatePanel.value = false;
        emit('update:selectedTemplate', selectedTemplate);
    }
};

const updateAdditionalNotes = (notes) => {
    emit('update:additionalNotes', notes);
};

const handleNotesChange = (event) => {
    const newNotes = event.target.value;
    additionalNotes.value = newNotes;
    updateAdditionalNotes(newNotes);
};


const selectTemplate = async (template) => {
    templatePanel.value = false;
    selectedTemplate.value = template;
    emit('update:selectedTemplate', selectedTemplate);
    trackEvent(RECORDER_TEMPLATE_SELECTED, { userId: user.value.uid, userEmail: user.value.email, sessionId: sessionId.value, patientId: patientId.value, templateId: template.id })
}


const followup = ref('');
const followupDate = ref('');
const followUpPanel = ref([0]);

const getFollowup = async () => {
    const getFollowupFunction = httpsCallable(functions, 'getFollowupSuggestions');

    const res = await getFollowupFunction({ patientId: patientId.value });
    if (!res.data) return followup.value = ''
    followup.value = res.data.followUp;
    emit('update:followupUsed', res.data.followUp);
    const followupTimestamp = res.data.date;
    if (followupTimestamp) {
        const { _seconds: seconds, _nanoseconds: nanoseconds } = followupTimestamp;
        const miliseconds = seconds * 1000 + nanoseconds / 1000;
        followupDate.value = Timestamp.fromMillis(miliseconds).toDate().toLocaleDateString();
    }
}

const selectedQuestionnaire = ref(null);
const questionnairePanel = ref(false)
const questionnaires = ref([]);

const getPatientQuestionnaires = async () => {
    const patientQuestionnaires = httpsCallable(functions, 'getPatientQuestionnaires');

    const res = await patientQuestionnaires({ patientId: patientId.value });
    questionnaires.value = res.data.questionnaires
        .filter(q => q.submitted)
        .sort((a, b) => (a.title?.toLowerCase() || '').localeCompare(b.title?.toLowerCase() || ''));
}

const selectQuestionnaire = questionnaire => {
    selectedQuestionnaire.value = questionnaire;
    questionnairePanel.value = false;
    emit('update:patientQuestionnaireId', id);
    trackEvent(RECORDER_QUESTIONNAIRE_SELECTED, { userId: user.value.uid, userEmail: user.value.email, sessionId: sessionId.value, patientId: patientId.value, questionnaireId: questionnaire.id })
}

const renderedMarkdown = computed(() => {
    if (followup.value) {
        return marked(followup.value);
    }
    else
        return ''
});

const additionalNotes = ref('');

const formatDate = (date) => {
    return moment(new Timestamp(date._seconds, date._nanoseconds).toDate()).format('MM-DD-YYYY HH:mm')
}


const formattedTime = computed(() => {
    const minutes = Math.floor(elapsedTime.value / 60);
    const seconds = elapsedTime.value % 60;
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
})

const discardDialog = ref(false);

const discard = async () => {
    setOverlay(true)
    stopRecording()
    discardDialog.value = false


    const deleteSessionAndAudioBySessionId = httpsCallable(functions, 'deleteSessionAndAudioBySessionId');
    try {
        await deleteSessionAndAudioBySessionId(sessionId.value)
        trackEvent(RECORDER_DISCARD_SESSION, { userId: user.value.uid, userEmail: user.value.email, sessionId: sessionId.value, patientId: patientId.value })
    } catch (error) {
        console.log(`Error deleting Session and Audio ${error}`)
        trackEvent(RECORDER_DISCARD_SESSION_ERROR, { userId: user.value.uid, userEmail: user.value.email, sessionId: sessionId.value, patientId: patientId.value, error: { errorName: error.name, errorMessage: error.message, errorStack: error.stack } })
    }
    finally {
        setOverlay(false)
        toggleDrawer()
        await clear()
    }
}

</script>

<style scoped>
.awc {
    width: 100%;
    border: 1px solid #ebebec;
    border-radius: 4px;

    background: #fff;
    height: 60px;
}

.actions {
    position: fixed;
    bottom: 0;
    background-color: white;
    z-index: 10;
    width: 100%;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.followup-section {
    background-color: white;
    border-radius: 4px;
    padding: 4px;
    font-family: inherit;
    font-size: inherit;
    line-height: 1.5;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 600;
}

.muted {
    color: var(--bittersweet);

}

.tooltip-text {
    color: rgb(255, 255, 255);
}
</style>